import React from 'react'
import { Link } from 'react-scroll';
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum';
import Button from '../../shared-components/Button/Button';

import photo from '../../utils/assets/main-photo.png';

const WelcomeComponent = () => {

    return (
        <div className="welcome-component container">
            <div className="welcome-wrapper">
                <h1 className="welcome-wrapper__hello">Hej!</h1>
                <h2 className="welcome-wrapper__big-text name">Jestem</h2>
                <h4 className="welcome-wrapper__small-text">Dietetyk kliniczny i&nbsp;psychodietetyk z&nbsp;wykształcenia, instruktor fitness i&nbsp;aquafitness, człowiek kochający jak "coś się dzieje" - witaj w&nbsp;moim świecie!</h4>
                <Link to="scroll-to-about" smooth offset={-50}>
                    <Button text={ ButtonTextEnum.SEE_MORE }/>
                </Link>
            </div>
            <div className="image-wrapper">
                <img src={ photo } alt="Main" />
            </div>
        </div>
    )
}

export default WelcomeComponent
