import React from 'react'

import fbIcon from '../../utils/assets/icons/fb.svg';
import igIcon from '../../utils/assets/icons/ig.svg';
import ytIcon from '../../utils/assets/icons/yt.svg';

const SocialMedia = () => {
    return (
        <ul className="social-media-list">
            <li className="social-media-list__item">
                <a href="https://facebook.com/zdrowoipozytywnie" target="_blank" rel="noreferrer" >
                    <img src={ fbIcon } alt="Facebook" />
                </a>
            </li>
            <li className="social-media-list__item">
                <a href="https://www.instagram.com/zdrowo_i_pozytywnie" target="_blank" rel="noreferrer">
                    <img src={ igIcon } alt="Instagram" />
                </a>
            </li>
            <li className="social-media-list__item">
                <a href="https://youtube.com/channel/UCkJP57TPG1dFDj65chdS7ig" target="_blank" rel="noreferrer">
                    <img src={ ytIcon } alt="YouTube" />
                </a>
            </li>
        </ul>
    )
}

export default SocialMedia
