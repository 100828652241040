import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import Button from '../../shared-components/Button/Button'
import Product from '../../shared-components/Product/Product';
import { ShopProduct } from '../../model/ShopProduct.model';
import { useDispatch } from 'react-redux';
import { saveAllShopProducts } from '../../redux/shopSlice';

interface Response {
    code: number;
    content: ShopProduct[];
}

const Offer = () => {
    const [products, setProducts] = useState<ShopProduct[]>();

    const dispatch = useDispatch();

    const getProducts = async() => {
        await fetch("https://zdrowoipozytywnie.pl/api/v1/getProducts")
            .then(resp => resp.json())
            .then((products: Response) => {
                const filteredProductsList = products.content.filter(el => el.product_order !== 4);
                setProducts(filteredProductsList);
                dispatch(saveAllShopProducts(products.content))

            });
    }

    useEffect(() => {
        getProducts();
        //eslint-disable-next-line
    }, [])

    return (
        <div className="offer-component-wrapper">
            <div className="offer-component container">
                <div className="offer-boxes-wrapper">
                    { products ? products.map((product, idx) => (
                        <Product key={ idx } product={ product } />
                    )) : ''}
                </div>
                <Link to="/shop">
                    <Button text={ ButtonTextEnum.FULL_OFFER } />
                </Link>
            </div>
        </div>
    )
}

export default Offer
