import React from 'react'
import { useSelector } from 'react-redux';
import { ProductTypeEnum } from '../../enums/Shop/ProductType/ProductType.enum';
import { RootState } from '../../redux/store';
import ContinuationCooperation from './ContinuationCooperation/ContinuationCooperation';
import NewCooperation from './NewCooperation/NewCooperation';
import PairContinuationCooperation from './PairContinuationCooperation/PairContinuationCooperation';
import PairNewCooperation from './PairNewCooperation/PairNewCooperation';

const CooperationInterview = () => {
    const interviewType = useSelector((state: RootState) => state.layout.modalInterviewType);

    const componentTypeRender = {
        [ProductTypeEnum.INDIVIDUAL_NEW]: <NewCooperation />,
        [ProductTypeEnum.INDIVIDUAL_CONTINUATION]: <ContinuationCooperation />,
        [ProductTypeEnum.PAIR_NEW]: <PairNewCooperation />,
        [ProductTypeEnum.PAIR_CONTINUATION]: <PairContinuationCooperation />
    }

    return (
        <div className="interview">
            { componentTypeRender[interviewType!] }
        </div>
    )
}

export default CooperationInterview
