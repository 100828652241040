import React from 'react'
import LatestRecipes from '../../components/LatestRecipes/LatestRecipes'
import MainAboutMe from '../../components/MainAboutMe/MainAboutMe'
import Offer from '../../components/Offer/Offer'
import WelcomeComponent from '../../components/WelcomeComponent/WelcomeComponent'

const HomePage = () => {
    return (
        <main className="sub-page-main">
            <WelcomeComponent />
            <LatestRecipes />
            <MainAboutMe />
            <Offer />
        </main>
    )
}

export default HomePage
