//eslint-disable-next-line
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
    isUserAuthenticated: boolean;
}

const initialState: AuthState = {
    isUserAuthenticated: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signInUser: (state) => {
            state.isUserAuthenticated = true;
        },
        signOutUser: (state) => {
            state.isUserAuthenticated = false;
        }
    },
})

export const { signInUser, signOutUser } = authSlice.actions;

export default authSlice.reducer;