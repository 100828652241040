import React, { useEffect, useState } from 'react'
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import { Recipe } from '../../model/Recipe.model'
import SingleRecipe from '../../shared-components/SingleRecipe/SingleRecipe'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'

interface Response {
    code: number;
    content: Recipe[];
}

const RecipesPage = () => {
    const [recipes, setRecipes] = useState<Recipe[]>([]);

    const getRecipes = async() => {
        await fetch("https://zdrowoipozytywnie.pl/api/v1/getRecipes")
            .then(resp => resp.json())
            .then((recipes: Response) => {
                const sortedList = recipes.content.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                setRecipes(sortedList);
            });
    }

    useEffect(() => {
        getRecipes();
        //eslint-disable-next-line
    }, [])

    return (
        <SubPageWrapper title={ PagesTitleEnum.RECIPES_PAGE }>
            <div className="recipes-page">
                { recipes ? recipes.map((el, idx) => (
                    <SingleRecipe key={ idx } recipe={ el } />     
                )) 
                : 
                ''
                }
            </div>
        </SubPageWrapper>
    )
}

export default RecipesPage
