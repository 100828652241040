import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum'
import { setModalContentType, toggleModalOpen } from '../../redux/layoutSlice'
import { RootState } from '../../redux/store'
import Button from '../../shared-components/Button/Button'

const ProductAdded = () => {
    const dispatch = useDispatch();
    const productTitle = useSelector((state: RootState) => state.shop.lastAddedProductTitle);

    const handleBackToShop = (): void => {
        dispatch(toggleModalOpen());
    }

    const handleGoToBasket = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.SHOP_BASKET));
    }

    return (
        <div className="product-added">
            <h2 className="product-added__title">Dodano produkt do koszyka</h2>
            <h3 className="product-added__product-name">{ productTitle }</h3>
            <div className="product-added__buttons-wrapper">
                <Button text={ ButtonTextEnum.BACK_TO_SHOP } action={ handleBackToShop } />
                <Button text={ ButtonTextEnum.GOT_TO_BASKET } action={ handleGoToBasket } />
            </div>
        </div>
    )
}

export default ProductAdded
