import React from 'react'
import { useDispatch } from 'react-redux';
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum';
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum';
import { BasketProduct } from '../../model/BasketProduct.model';
import { setCurrentBasketProductInterviewUUID } from '../../redux/shopSlice';
import { setModalContentType, setModalInterviewType, setModalInterviewEdit, toggleModalOpen } from '../../redux/layoutSlice';
import { removeProductFromBasket } from '../../redux/shopSlice';
import Button from '../Button/Button';

interface Props {
    products: BasketProduct[];
    orderRealization: boolean;
}

const OrderProductsTable = ({ products, orderRealization }: Props) => {
    const dispatch = useDispatch();

    const handleDeleteProductFromBasket = (idx: number): void => {
        const updatedProductsArr = [...products];
        updatedProductsArr.splice(idx, 1);
        dispatch(removeProductFromBasket(updatedProductsArr));
    }

    const handleOpenInterviewModal = (el: BasketProduct, isEdit?: boolean): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.INTERVIEW))
        dispatch(setModalInterviewType(el.shopProduct.type))
        dispatch(setCurrentBasketProductInterviewUUID(el.basketProductUUID))

        if (isEdit) {
            dispatch(setModalInterviewEdit());
        }

        dispatch(toggleModalOpen())
    }

    return (
        <div className="products">
            <ul className="heading">
                <li className="product-el">Produkt</li>
                <li className="length-el">Dieta na</li>
                <li className="price-el">Cena</li>
                { orderRealization ? <li className="actions-el">Ankieta</li> : <li className="delete-el">Usuń</li> }
            </ul>
            { products.length > 0 ? products.map((el, idx) => (
                <ul key={ idx } className="body">
                    <li className="product-el">
                        <img src={el.shopProduct.img} alt="food" />
                        <p>{el.shopProduct.title}</p>
                    </li>
                    <li className="length-el">{ el.dietLength } dni</li>
                    <li className="price-el">{el.price}zł</li>

                    { orderRealization ? 
                        <li className="actions-el">
                            { !el.isInterviewFilled ?  
                                <Button text={ ButtonTextEnum.FILL_INTERVIEW } action={ () => handleOpenInterviewModal(el, false) } />
                                :
                                <Button text={ ButtonTextEnum.EDIT_INTERVIEW } action={ () => handleOpenInterviewModal(el, true) } />
                            }
                        </li>
                        :
                        <li className="delete-el">
                            <div className="delete-wrapper" onClick={ () => handleDeleteProductFromBasket(idx) }>
                                <span className="delete"></span>
                            </div>
                        </li>
                    }

                </ul>
                
            )) :
                <div className="empty-basket">
                    <h2>Twój koszyk jest pusty</h2>
                </div>
            }
        </div>
    )
}

export default OrderProductsTable
