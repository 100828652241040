import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'
import { ShopProduct } from '../../model/ShopProduct.model';
import SingleOfferFullView from '../../components/Offer/SingleFullOfferView/SingleFullOfferView';

interface Response {
    code: number;
    content: ShopProduct[];
}

const OfferSingleProductPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [product, setProduct] = useState<ShopProduct>()

    const getProductById = async(id: string) => {
        // const productsCol = collection(db, 'products');
        // const productsSnapshot = await getDocs(productsCol);
        // const productsList = productsSnapshot.docs.map(doc => doc.data() as ShopProduct);
        // const product = productsList.filter(el => el.id === id);

        await fetch("https://zdrowoipozytywnie.pl/api/v1/getProduct/" + id)
        .then(resp => resp.json())
        .then((product: Response) => {
            if (product.content.length > 0) {
                setProduct(product.content[0]);
            } else {
                redirectToHome();
            }
        });
    }

    const redirectToHome = (): void => {
        navigate('/')
    }

    useEffect(() => {
        if (id) {
            getProductById(id);
        }
        //eslint-disable-next-line
    }, [id])

    return (
        <SubPageWrapper title={ PagesTitleEnum.PRODUCT_VIEW }>
            { product ? <SingleOfferFullView shopProduct={ product } /> : "" }
        </SubPageWrapper>
    )
}

export default OfferSingleProductPage
