import React from 'react'
import { Link } from 'react-router-dom';
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum';
import Button from '../../shared-components/Button/Button';
import photo from '../../utils/assets/about-main.jpeg';

const MainAboutMe = () => {
    return (
        <div className="main-about-me-component container" id="scroll-to-about">
            <div className="main-about-me-component__photo">
                <img src={ photo } alt="Main me" />
            </div>
            <div className="main-about-me-component__about">
                <h2 className="main-about-me-component__about__title">O mnie</h2>
                <p className="main-about-me-component__about__text">
                    Cześć, witam Cię serdecznie! Z tej strony Natalia Galus. Jestem Dietetykiem Klinicznym i&nbsp;Psychodietetykiem, a także instruktorem fitness&nbsp;i&nbsp;aquafitness – zapraszam do mojego świata dietetyki i sportu!
                    Zdrowo i&nbsp;pozytywnie to moje ulubione słowa (i&nbsp;nazwa na Instagramie), pasujące do
                    mojego stylu życia. Łączą w sobie odżywcze i pyszne jedzenie oraz radość wynikającą z
                    podejmowania aktywności fizycznej i dbania o siebie. Jeśli Tobie też podoba się takie
                    podejście do życia, zapraszam do zapoznania się z pełnym opisem mojej osoby i&nbsp;oferty
                    współpracy.
                </p>
                <Link to="/about">
                    <Button text={ ButtonTextEnum.READ_MORE } />
                </Link>
            </div>
        </div>
    )
}

export default MainAboutMe
