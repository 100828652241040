import React from 'react'

const OrderConfirmation = () => {

    return (
        <div className="order-pre-confirmation">
            <h2 className="order-pre-confirmation__title">Zamówienie wysłane</h2>
            <div className="order-pre-confirmation__payment-info">
                <h2 className="order-info">Na podany adres mailowy otrzymasz potwierdzenie złożenia zamówienia wraz z informacjami o płatności</h2>
                <div className="data-wrapper">
                    <div className="data-box">
                        <h3 className="data-box-title">Imię i nazwisko:</h3>
                        <p className="data-box-value">Natalia Galus</p>
                    </div>
                    <div className="data-box">
                        <h3 className="data-box-title">Numer konta:</h3>
                        <p className="data-box-value">30 1240 1910 1111 0010 4269 1070</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderConfirmation
 