import React, { useEffect, useState } from 'react'
import { Recipe } from '../../model/Recipe.model';
import LatestRecipe from './LatestRecipe/LatestRecipe';

interface Response {
    code: number;
    content: Recipe[];
}

const LatestRecipes = () => {

    const [activeRecipe, setActiveRecipe] = useState(0);

    const [recipes, setRecipes] = useState<Recipe[]>([]);

    const getLatestRecipes = async() => {
        await fetch("https://zdrowoipozytywnie.pl/api/v1/getRecipes")
            .then(resp => resp.json())
            .then((recipes: Response) => {
                const sortedList = recipes.content.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                const slicedList = sortedList.slice(0, 4);
                setRecipes(slicedList);
            });
    }

    useEffect(() => {
        getLatestRecipes();
        //eslint-disable-next-line
    }, [])

    const maxRecipeIdx = recipes.length - 1;

    const changeActiveRecipe = ():void => {
        if (activeRecipe < maxRecipeIdx) {
            setActiveRecipe(prevState => prevState + 1);
        } else {
            setActiveRecipe(1);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            changeActiveRecipe();
        }, 4000);

        return () => clearInterval(interval);
        //eslint-disable-next-line
    })

    return (
        <div className="latest-recipes-component-wrapper">
            <div className="latest-recipes container">
                <h2 className="latest-recipes__title">MOJE NAJNOWSZE PRZEPISY</h2>
                <div className="latest-recipes__wrapper">
                    { recipes ? recipes.map((el, idx) => (
                        <LatestRecipe recipe={ el } key={ idx } isActive={ activeRecipe === idx ? true : false } />
                    )) : "" }
                </div>
            </div>
        </div>
    )
}

export default LatestRecipes
