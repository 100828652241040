import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { ShopProduct } from '../../model/ShopProduct.model'
import Button from '../Button/Button'

interface Props {
    product: ShopProduct
}

const Product = ({ product }: Props) => {

    //eslint-disable-next-line
    const { id, img, title, sub_title, product_order } = product;

    return (
        <div className={`single-product order-${ product_order }`}>
            <img src={ img } alt="Offer" />
            <p className="single-product__title">{ sub_title }</p>
            <Link to={`/productView/${id}`}>
                <Button text={ ButtonTextEnum.READ_MORE } />
            </Link>
        </div>
    )
}

export default Product
