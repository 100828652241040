import signInIco from '../../utils/assets/icons/SignIn-ico.svg';
import signOutIco from '../../utils/assets/icons/SignOut-ico.svg';
import shopBagIco from '../../utils/assets/icons/ShopBag-ico.svg';
import userIco from '../../utils/assets/icons/User-ico.png'

export enum HeaderButtonEnum {
    SIGN_IN = 'LOGOWANIE',
    SHOP_BAG = 'KOSZYK',
    USER_ACCOUNT = "PANEL KLIENTA",
    SIGN_OUT = "WYLOGUJ SIĘ",
}

export const HeaderButtonIcon = {
    SIGN_IN_ICO: signInIco,
    SHOP_BAG_ICO: shopBagIco,
    USER_ACCOUNT_ICO: userIco,
    SIGN_OUT_ICO: signOutIco
}