import React from 'react'
import { ShopProduct } from '../../../model/ShopProduct.model'
import reactStringReplace from 'react-string-replace';
import { Link } from 'react-router-dom';
import ex1 from '../../../utils/assets/example-diet/page1.jpg';
import ex2 from '../../../utils/assets/example-diet/pag2.jpg';
import ex3 from '../../../utils/assets/example-diet/pag3.jpg';
import ex10 from '../../../utils/assets/example-diet/pag10.jpg';


interface Props {
    shopProduct: ShopProduct
}

const SingleOfferFullView = ({ shopProduct }: Props) => {
    return (
        <div className="single-offer-full-view">
            <div className="img-title-box">
                <img src={shopProduct.img} alt="Product" />
                <div className="img-title-box__text">
                    <h2 className="img-title-box__title">{ shopProduct.title }</h2>
                    <h3 className="img-title-box__subtitle">{ shopProduct.sub_title }</h3>
                </div>
            </div>
            <div className="description-box">
                <h2 className="description-box__title">Opcja ta zawiera:</h2>
                <ul>
                    { shopProduct.included_in.map((el, idx) => (
                        <li key={ idx } className="description-el">{ el }</li>
                    )) }
                </ul>
            </div>
            <div className="description-box">
                <h2 className="description-box__title">Jak skorzystać?</h2>
                <ul>
                    { shopProduct.description.map((el, idx) => (
                        <li key={ idx } className="description-el">{ reactStringReplace(el, 'sklepu', (match, i) => (<Link key={idx} to="/shop" className="shop-url">sklepu</Link>) ) }</li>
                    )) }
                </ul>
            </div>
            <div className="example-diet-box">
                <h2 className="example-diet-box__title">Przykładowa dieta</h2>
                <div className="example-diet-box__images-wrapper">
                    <img src={ ex1 } alt="example-diet" />
                    <img src={ ex2 } alt="example-diet" />
                    <img src={ ex3 } alt="example-diet" />
                    <img src={ ex10 } alt="example-diet" />
                </div>
            </div>
        </div>
    )
}

export default SingleOfferFullView
