export enum InterviewInputPlaceholderEnum {
    fullName = "Imię i nazwisko:",
    email = "Adres e-mail:",
    phoneNumber = "Nr telefonu:",
    birthDate = "Data urodzenia:",
    visitPurpose = "Cel wizyty:",
    weight = "Masa ciała:",
    height = "Wzrost:",
    waist = "Obwód talii:",
    chest = "Obwód klatki piersiowej:",
    hips = "Obwód bioder",
    thighs = "Obwód uda:",
    arm = "Obwód ramienia:",
    pregnant = "Ciąża:",
    breastFeeding = "Karmienie piersią:",
    diseaseUnits = "Jednostki chorobowe:",
    medicines = "Stosowane leki, suplementy:",
    foodIntolerance = "Nietolerancje pokarmowe, alergie:",
    physicalActivity = "Aktywność fizyczna:",
    wakeUpHours = "Godziny wstawania: (najczęściej)",
    goSleepHours = "Godziny pójścia spać: (najczęściej)",
    preferredNumberOfMeals = "Preferowana ilość posiłków w diecie:",
    eatingHours = "Godziny spożywania posiłków:",
    dislikedFood = "Nielubiane produkty/potrawy:",
    likedFood = "Ulubione produkty/potrawy:",
    drinks = "Wypijane napoje:",
    additionalInformations = "Dodatkowe informacje:",
    glucose = "Glukoza:",
    cholesterol = "Cholesterol:",
    ldl = "LDL:",
    hdl = "HDL:",
    trigliceryds = "Triglicerydy:",
    tsh = "TSH:",
    liverTests = "Próby wątrobowe - ALT, AST, ALP:",
    others = "Inne:"

}