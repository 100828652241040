import React from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum';
import { setModalContentType, toggleModalOpen } from '../../redux/layoutSlice';
import SocialMedia from '../../shared-components/SocialMedia/SocialMedia';
import logo from '../../utils/assets/ZiP-logo.svg';

const Footer = () => {
    const dispatch = useDispatch();

    const getYear = () => new Date().getFullYear();

    const handlePrivacyPolicyOpen = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.PRIVACY_POLICY));
        dispatch(toggleModalOpen());
    }

    const handleRulesOpen = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.SHOP_RULES));
        dispatch(toggleModalOpen())
    }

    return (
        <footer className="footer-component-wrapper">
            <div className="footer container">
                <div className="footer-content">
                    <div className="content-wrapper">
                        <nav className="footer-content__nav">
                            <h3>Menu</h3>
                            <ul>
                                <li>
                                    <Link to="/">Strona główna</Link>
                                </li>
                                <li>
                                    <Link to="about">O mnie</Link>
                                </li>
                                <li>
                                    <Link to="recipes">Przepisy</Link>
                                </li>
                                <li>
                                    <Link to="shop">Sklep</Link>
                                </li>
                                <li>
                                    <Link to="contact">Kontakt</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="content-wrapper">
                        <div className="footer-content__social-media">
                            <img className="logo" src={ logo } alt="Logo" />
                            <SocialMedia />
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className="footer-content__rules">
                            <h3>Inne</h3>
                            <ul>
                                <li onClick={ handlePrivacyPolicyOpen }>Polityka prywatności, cookies</li>
                                <li onClick={ handleRulesOpen }>Regulamin sklepu</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    &#169; { getYear() } | Created by <a href="https://cosmocode.pl/" target="_blank" rel="noreferrer">CosmoCode</a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
