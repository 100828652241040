import React from 'react'
import { Link } from 'react-router-dom';
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'

import photo1 from '../../utils/assets/about-me1.jpg';
import photo2 from '../../utils/assets/about-me2.jpeg';

const AboutMePage = () => {
    return (
        <SubPageWrapper title={ PagesTitleEnum.ABOUT_ME_PAGE } additionalClassName="">
            <div className="about-page">
                <div className="row">
                    <img className="about-image" src={ photo1 } alt="Me" />
                    <div className="about-text">
                        <p className="about-text__title--bold">Cieszę się, że chcesz poznać mnie bliżej – zapraszam do lektury!</p>
                        <p>Jestem Dietetykiem Klinicznym i&nbsp; Psychodietetykiem, ukończyłam studia licencjackie z&nbsp; Dietetyki Klinicznej na Śląskim Uniwersytecie Medycznym oraz studia magisterskie z Psychodietetyki w Śląskiej Wyższej Szkole Medycznej w Katowicach -&gt; dzięki temu potrafię zmotywować Cię do zmiany swojego stylu życia na zdrowszy, jak i ułożyć odpowiedni dla Ciebie plan żywieniowy, który uwzględni stan Twojego zdrowia.</p>
                        <p>Poza dietetyką uwielbiam sport, od najmłodszych lat. Aktualnie prowadzę zajęcia fitness i aquafitness – to moja pasja i sposób na dbanie o zdrowie. Moich klientów ćwiczących ze mną zachęcam do zdrowego odżywiania, a pacjentów do aktywności fizycznej -&gt; uważam, że te dwa aspekty są konieczne, by być zdrowym i mieć dobre samopoczucie.</p>
                    </div>
                    <div className="about-text bottom-part">
                        <p>Od dziecka jestem bardzo aktywna; przez kilka lat tańczyłam taniec nowoczesny, później byłam cheerleaderką, próbowałam też swoich sił w hip-hopie... Przez 6 lat trenowałam lekkoatletykę, biegałam na średnich i długich dystansach, jeździłam na wiele obozów sportowych jako członkini kadry małopolski, brałam udział w zawodach lekkoatletycznych na różnym szczeblu. Bieganie nauczyło mnie samodyscypliny, samozaparcia, systematyczności i tego, że można naprawdę wiele osiągnąć, jeśli wytrwale o coś walczymy i poświęcamy temu swój czas i energię. Staram się wykorzystywać te umiejętności w pracy z klientem i w pracy instruktora fitness -&gt; po to, abyś Ty też poczuł się zmotywowany/a i uwierzył/a, że możesz osiągnąć założony przez siebie cel, bez wielkich wyrzeczeń i jedzenia przysłowiowej sałaty.</p>
                    </div>
                </div>
                <span className="separator"></span>
                <div className="row">
                    <div className="about-text">
                        <p className="about-text__title--bold">Zapraszam Cię serdecznie do współpracy, jeśli:</p>
                        <ul>
                            <li>Chcesz zadbać o swoje zdrowie od strony dietetycznej</li>
                            <li>Chcesz odżywiać się zdrowo, ale nie chcesz rezygnować z ulubionych potraw i produktów</li>
                            <li>Chcesz osiągnąć wymarzoną masę ciała i wygląd sylwetki</li>
                            <li>Chcesz poprawić stan swojego zdrowia</li>
                            <li>Chcesz być na diecie dopasowanej do jednostek chorobowych, z którymi się zmagasz</li>
                            <li>Chcesz mieć wsparcie i motywację w drodze do zmiany nawyków żywieniowych</li>
                            <li>Chcesz, by czuwała nad Tobą osoba z wykształceniem i doświadczeniem, do której możesz zgłosić się w każdej chwili z pytaniem czy radą</li>
                            <li>Nie chcesz całkowicie rezygnować z jedzenia słodyczy, fast foodów czy okazjonalnego spożywania alkoholu – to wszystko jest możliwe, można odpowiednio zbilansować dietę umieszczając w niej i takie produkty</li>
                        </ul>
                        <p className="navigation-to-shop-text">
                            Poznaj ofertę współpracy tutaj -&gt; <Link to="/shop">Oferta</Link> i zadbaj o siebie, o swoje zdrowie i samopoczucie. Zapraszam!
                        </p>
                    </div>
                    <img className="about-image" src={ photo2 } alt="Me" />
                </div>
            </div>
        </SubPageWrapper>
    )
}

export default AboutMePage
