import React from 'react'
import { useSelector } from 'react-redux';
import { HeaderButtonEnum, HeaderButtonIcon } from '../../enums/HeaderButton/HeaderButton.enum';
import { RootState } from '../../redux/store';

import logo from '../../utils/assets/ZiP-logo.svg';
import HamburgerMenuButton from './HamburgerMenuButton/HamburgerMenuButton';
import HeaderButton from './HeaderButton/HeaderButton';

const Header = () => {
    const isUserAuthenticated = useSelector((state: RootState) => state.auth.isUserAuthenticated);

    return (
        <header className="header">
            <div className="container">
                <div className="logo-wrapper">
                    <img src={logo} alt="Logo" className="logo-wrapper__logo" />
                </div>
                <HamburgerMenuButton />
                <div className="user-nav-wrapper">
                    <HeaderButton text={ HeaderButtonEnum.SHOP_BAG } icon={ HeaderButtonIcon.SHOP_BAG_ICO } />
                    { isUserAuthenticated ? 
                        <>
                            <HeaderButton text={ HeaderButtonEnum.USER_ACCOUNT } icon={ HeaderButtonIcon.USER_ACCOUNT_ICO } />
                            <HeaderButton text={ HeaderButtonEnum.SIGN_OUT } icon={ HeaderButtonIcon.SIGN_OUT_ICO } />
                        </>
                        :
                        <HeaderButton text={ HeaderButtonEnum.SIGN_IN } icon={ HeaderButtonIcon.SIGN_IN_ICO } />
                    }
                </div>
            </div>
        </header>
    )
}

export default Header
