import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketProduct } from '../model/BasketProduct.model';
import { InterviewModel } from '../model/Interview.model';
import { ShopProduct } from '../model/ShopProduct.model';

export interface ShopState {
    products: BasketProduct[],
    lastAddedProductTitle: string,
    currentBasketProductInterviewUUID: string,
    allShopProducts: ShopProduct[]; 
}

const initialState: ShopState = {
    products: [],
    lastAddedProductTitle: '',
    currentBasketProductInterviewUUID: '',
    allShopProducts: [],
}

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        addProductToBasket: (state, action: PayloadAction<BasketProduct>) => {
            state.products.push(action.payload);
            state.lastAddedProductTitle = action.payload.shopProduct.title
        },
        removeProductFromBasket: (state, action: PayloadAction<BasketProduct[]>) => {
            state.products = action.payload;
        },
        setCurrentBasketProductInterviewUUID: (state, action: PayloadAction<string>) => {
            state.currentBasketProductInterviewUUID = action.payload
        },
        saveProductInterview: (state, action: PayloadAction<InterviewModel>) => {
            state.products.forEach((el) => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.interview = action.payload
                    el.isInterviewFilled = true
                }
            })
        },
        cleanShopState: (state) => {
            state.products = [];
            state.lastAddedProductTitle = '';
            state.currentBasketProductInterviewUUID = '';
        },
        savePairInterview: (state, action: PayloadAction<InterviewModel>) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.pairInterviews.push(action.payload)
                }
            })
        },
        setFirstPersonInterviewFilled: (state) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.firstPersonPairInterviewFilled = true;
                }
            })
        },
        setSecondPersonInterviewFilled: (state) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.secondPersonPairInterviewFilled = true;
                }
            })
        },
        setPairInterviewsFilled: (state) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.isInterviewFilled = true
                }
            })
        },
        saveEditedFirstPersonInterview: (state, action: PayloadAction<InterviewModel>) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.pairInterviews[0] = action.payload
                }
            })
        },
        saveEditedSecondPersonInterview: (state, action: PayloadAction<InterviewModel>) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.pairInterviews[1] = action.payload
                }
            })
        },
        setFirstPersonPairInterviewEditFilled: (state) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.firstPersonPairInterviewEditFilled = true;
                }
            })
        },
        setFirstPersonPairInterviewEditNotFilled: (state) => {
            state.products.forEach(el => {
                if (el.basketProductUUID === state.currentBasketProductInterviewUUID) {
                    el.firstPersonPairInterviewEditFilled = false;
                }
            })
        },
        saveAllShopProducts: (state, action: PayloadAction<ShopProduct[]>) => {
            state.allShopProducts = action.payload
        }
    },
})

export const { addProductToBasket, removeProductFromBasket, setFirstPersonPairInterviewEditNotFilled, saveAllShopProducts, setFirstPersonPairInterviewEditFilled, saveEditedFirstPersonInterview, saveEditedSecondPersonInterview, setPairInterviewsFilled, setCurrentBasketProductInterviewUUID, saveProductInterview, cleanShopState, savePairInterview, setFirstPersonInterviewFilled, setSecondPersonInterviewFilled } = shopSlice.actions;

export default shopSlice.reducer;