import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTextEnum } from '../../../enums/Button/ButtonText.enum'
import { InterviewInputPlaceholderEnum } from '../../../enums/Shop/InterviewInputPlaceholder/InterviewInputPlaceholder.enum'
import { InterviewPhysicalActivityOptionsEnum } from '../../../enums/Shop/InterviewPhysicalActivityOptions/InterviewPhysicalActivityOptions'
import { InterviewModel } from '../../../model/Interview.model'
import { setModalInterviewNotEdit, toggleModalOpen } from '../../../redux/layoutSlice'
import { saveEditedFirstPersonInterview, saveEditedSecondPersonInterview, savePairInterview, saveProductInterview, setFirstPersonInterviewFilled, setFirstPersonPairInterviewEditFilled, setFirstPersonPairInterviewEditNotFilled, setPairInterviewsFilled, setSecondPersonInterviewFilled } from '../../../redux/shopSlice'
import { RootState } from '../../../redux/store'
import Button from '../../../shared-components/Button/Button'

interface Props {
    pairCooperation?: boolean,
    personNumber?: 1 | 2
}

const ContinuationCooperation = ({ pairCooperation, personNumber }: Props) => {
    const getEmptyInterview = (): InterviewModel => {
        const emptyInterview = new InterviewModel();
        emptyInterview.fullName = "";
        emptyInterview.email = "";
        emptyInterview.phoneNumber = "";
        emptyInterview.birthDate = "";
        emptyInterview.visitPurpose = "";
        emptyInterview.weight = "";
        emptyInterview.height = "";
        emptyInterview.waist = "";
        emptyInterview.chest = "";
        emptyInterview.hips = "";
        emptyInterview.thighs = "";
        emptyInterview.arm = "";
        emptyInterview.pregnant = "";
        emptyInterview.breastFeeding = "";
        emptyInterview.diseaseUnits = "";
        emptyInterview.medicines = "";
        emptyInterview.foodIntolerance = "";
        emptyInterview.physicalActivity = "";
        emptyInterview.wakeUpHours = "";
        emptyInterview.goSleepHours = "";
        emptyInterview.preferredNumberOfMeals = "";
        emptyInterview.eatingHours = "";
        emptyInterview.dislikedFood = "";
        emptyInterview.likedFood = "";
        emptyInterview.drinks = "";
        emptyInterview.additionalInformations = "";
        emptyInterview.glucose = "";
        emptyInterview.cholesterol = "";
        emptyInterview.ldl = "";
        emptyInterview.hdl = "";
        emptyInterview.trigliceryds = "";
        emptyInterview.tsh = "";
        emptyInterview.liverTests = "";
        emptyInterview.others = "";

        return emptyInterview
    }

    const state = useSelector((state: RootState) => state);

    const disptach = useDispatch();

    const getInterviewToEdit = (): InterviewModel => {
        const allInterviews = [...state.shop.products];
        const interviewToEdit = allInterviews.filter(el => el.basketProductUUID === state.shop.currentBasketProductInterviewUUID);

        return interviewToEdit[0].interview;
    }

    const getPairInterviewToEdit = (): InterviewModel => {
        const allInterviews = [...state.shop.products];
        const interviewToEdit = allInterviews.filter(el => el.basketProductUUID === state.shop.currentBasketProductInterviewUUID);

        if (personNumber === 1) {
            return interviewToEdit[0].pairInterviews[0]
        } else if (personNumber === 2) {
            return interviewToEdit[0].pairInterviews[1]
        } else {
            return getEmptyInterview();
        }
    }

    const getInterview = (): InterviewModel => {
        if (state.layout.isInterviewModalEdit) {
            if (pairCooperation) {
                return getPairInterviewToEdit();
            }
            return getInterviewToEdit();
        } else {
            return getEmptyInterview();
        }
    }


    const [form, setForm] = useState(getInterview())
    const [validateForm, setValidateForm] = useState(false);
    const [isFormValidatedOk, setIsFormValidatedOk] = useState(false);

    const handleInputChange = (e: any) => {
        e.persist()
        setForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSaveForm = (): void => {
        setValidateForm(true);

        if (isFormValidatedOk) {
            if (pairCooperation) {
                if (personNumber === 1) {
                    if (state.layout.isInterviewModalEdit) {
                        disptach(saveEditedFirstPersonInterview(form))
                        disptach(setFirstPersonPairInterviewEditFilled())
                    } else {
                        disptach(savePairInterview(form))
                        disptach(setFirstPersonInterviewFilled())
                    }
                } else if (personNumber === 2) {
                    if (state.layout.isInterviewModalEdit) {
                        disptach(saveEditedSecondPersonInterview(form))
                        disptach(setPairInterviewsFilled())
                        disptach(toggleModalOpen());
                        disptach(setModalInterviewNotEdit())
                        disptach(setFirstPersonPairInterviewEditNotFilled())
                    } else {
                        disptach(savePairInterview(form))
                        disptach(setSecondPersonInterviewFilled())
                        disptach(setPairInterviewsFilled())
                        disptach(toggleModalOpen());
                        disptach(setModalInterviewNotEdit());
                    }
                }
            } else {
                disptach(saveProductInterview(form))
                disptach(toggleModalOpen());
                disptach(setModalInterviewNotEdit());
            }
        }
    }

    const isFieldRequired = (fieldName: string): boolean => {
        const notRequiredFields = ['type', 'phoneNumber', 'birthDate', 'visitPurpose', 'height', 'waist', 'chest', 'hips', 'thighs', 'arm', 'additionalInformations', 'others', 'diseaseUnits', 'medicines', 'foodIntolerance', 'wakeUpHours', 'goSleepHours', 'preferredNumberOfMeals', 'eatingHours', 'dislikedFood', 'likedFood', 'drinks', 'glucose', 'cholesterol', 'ldl', 'hdl', 'trigliceryds', 'tsh', 'liverTests']
        
        if (!checkIfEmpty(fieldName) && validateForm) {
            return notRequiredFields.indexOf(fieldName) === -1;
        } else {
            return false;
        }
    }

    const validateFormFieldsNotEmpty = () => {
        const notRequiredFields = ['type', 'phoneNumber', 'birthDate', 'visitPurpose', 'height', 'waist', 'chest', 'hips', 'thighs', 'arm', 'additionalInformations', 'others', 'diseaseUnits', 'medicines', 'foodIntolerance', 'wakeUpHours', 'goSleepHours', 'preferredNumberOfMeals', 'eatingHours', 'dislikedFood', 'likedFood', 'drinks', 'glucose', 'cholesterol', 'ldl', 'hdl', 'trigliceryds', 'tsh', 'liverTests']

        const formClone = Object.assign({}, form);

        notRequiredFields.forEach(el => delete formClone[el as keyof InterviewModel])

        const isFormValidatedOk = Object.values(formClone).every(el => el);
        
        setIsFormValidatedOk(isFormValidatedOk);
    }

    const checkIfEmpty = (fieldName: string): boolean => {
        return !!form[fieldName as keyof InterviewModel]!
    }

    useEffect(() => {
        if (pairCooperation) {
            setForm(getInterview())
        }
        //eslint-disable-next-line
    }, [personNumber])

    useEffect(() => {
        validateFormFieldsNotEmpty()
        //eslint-disable-next-line
    }, [form])

    return (
        <div className="form-wrapper">
            <form className="form">
                { Object.keys(form).map((el, idx) => {
                    if (el === 'email') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <input className={isFieldRequired(el) ? 'required-field' : ''} onChange={ handleInputChange } type="email" name={el} id={el} value={ form[el] } />
                                <span></span>
                            </div>
                        )
                    } else if (el === 'fullName' || el === 'weight' || el === 'waist' || el === 'chest' || el === 'hips' || el === 'thighs' || el === 'breastFeeding') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <input className={isFieldRequired(el) ? 'required-field' : ''} onChange={ handleInputChange } type="text" name={el} id={el} value={ form[el] } />
                                <span></span>
                            </div>
                        )
                    } else if (el === 'arm') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <input className={isFieldRequired(el) ? 'required-field' : ''} onChange={ handleInputChange } type="text" name={el} id={el} value={ form[el as keyof InterviewModel] } placeholder="(połowa odległości między barkiem, a łokciem)" />
                                <span></span>
                            </div>
                        )
                    } else if (el === 'physicalActivity') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <select defaultValue={ form[el as keyof InterviewModel] } className={isFieldRequired(el) ? 'required-field' : ''} name={ el } onChange={ handleInputChange }>
                                        <option value="" hidden disabled={true}>Wybierz rodzaj aktywności</option>
                                    { Object.keys(InterviewPhysicalActivityOptionsEnum).map((el2, idx) => (
                                        <option key={el2} value={ InterviewPhysicalActivityOptionsEnum[el2 as keyof typeof InterviewPhysicalActivityOptionsEnum] }>
                                            {InterviewPhysicalActivityOptionsEnum[el2 as keyof typeof InterviewPhysicalActivityOptionsEnum]}
                                        </option>
                                    )) }
                                </select>
                                <span></span>
                            </div>
                        )
                    } else if (el === 'pregnant') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <input className={isFieldRequired(el) ? 'required-field' : ''} onChange={ handleInputChange } type="text" name={el} id={el} value={ form[el as keyof InterviewModel] } placeholder="tak + jeśli tak, to który trymestr / nie" />
                                <span></span>
                            </div>
                        )
                    } else if (el === 'additionalInformations') {
                        return (
                            <div key={idx} className="input-wrapper">
                                <label htmlFor={el}>{InterviewInputPlaceholderEnum[el as keyof typeof InterviewInputPlaceholderEnum]}</label>
                                <textarea className={isFieldRequired(el) ? 'required-field' : ''} onChange={ handleInputChange } name={el} id={el} value={ form[el as keyof InterviewModel] }></textarea>
                                <span></span>
                            </div>
                        )
                    } else {
                        return null
                    }
                }) }
                <div className="button-wrapper">
                    <Button text={ ButtonTextEnum.SAVE } action={ handleSaveForm } />
                </div>
            </form>
        </div>
    )
}

export default ContinuationCooperation
