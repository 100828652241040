import React, { useEffect, useState } from 'react'
import store from '../../../redux/store';
import NewCooperation from '../NewCooperation/NewCooperation'

const PairNewCooperation = () => {

    const isEditModal = store.getState().layout.isInterviewModalEdit;
    const [isFirstPersonInterviewFilled, setIsFirstPersonInterviewFilled] = useState<boolean>(false);
    const [isFirstPersonInterviewEditFilled, setIssFirstPersonInterviewEditFilled] = useState<boolean>(false);

    const checkIfIsFilled = (): boolean => {
        if (store.getState().shop.products.length > 0) {
            return store.getState().shop.products.filter(el => el.basketProductUUID === store.getState().shop.currentBasketProductInterviewUUID)[0].firstPersonPairInterviewFilled
        } else {
            return false
        }
    }

    const checkIfEditIsFilled = (): boolean => {
        if (store.getState().shop.products.length > 0) {
            return store.getState().shop.products.filter(el => el.basketProductUUID === store.getState().shop.currentBasketProductInterviewUUID)[0].firstPersonPairInterviewEditFilled
        } else {
            return false
        }
    }

    useEffect(() => {
        const sub = store.subscribe(() => {
            const isFilled = checkIfIsFilled();
            const isEditFilled = checkIfEditIsFilled();

            setIsFirstPersonInterviewFilled(isFilled);
            setIssFirstPersonInterviewEditFilled(isEditFilled);
        })

        setIsFirstPersonInterviewFilled(checkIfIsFilled);
        setIssFirstPersonInterviewEditFilled(checkIfEditIsFilled)

        return () => sub();
    }, [])
    
    return (
        <div className="pair-new-cooperation">
            { !isEditModal ?
                !isFirstPersonInterviewFilled ? 
                    <div className="interview-wrapper">
                        <h2 className="interview-wrapper__title">Pierwsza osoba</h2>
                        <h3 className="interview-wrapper__subtitle">(pola z gwiazdką są wymagane)</h3>
                        <NewCooperation pairCooperation personNumber={ 1 }/>
                    </div>
                    :
                    <div className="interview-wrapper">
                        <h2 className="interview-wrapper__title">Druga osoba</h2>
                        <h3 className="interview-wrapper__subtitle">(podkreslone pola są wymagane)</h3>
                        <NewCooperation pairCooperation personNumber={ 2 }/>
                    </div>
                :
                !isFirstPersonInterviewEditFilled ?
                    <div className="interview-wrapper">
                        <h2 className="interview-wrapper__title">Pierwsza osoba</h2>
                        <h3 className="interview-wrapper__subtitle">(podkreslone pola są wymagane)</h3>
                        <NewCooperation pairCooperation personNumber={ 1 }/>
                    </div>
                    :
                    <div className="interview-wrapper">
                        <h2 className="interview-wrapper__title">Druga osoba</h2>
                        <h3 className="interview-wrapper__subtitle">(podkreslone pola są wymagane)</h3>
                        <NewCooperation pairCooperation personNumber={ 2 }/>
                    </div>
            }

        </div>
    )
}

export default PairNewCooperation
