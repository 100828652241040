import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButtonEnum } from '../../../enums/HeaderButton/HeaderButton.enum';
import { ModalContentTypeEnum } from '../../../enums/Modal/ModalContentType.enum';
import { signOutUser } from '../../../redux/authSlice';
import { setModalContentType, toggleModalOpen } from '../../../redux/layoutSlice';
import { RootState } from '../../../redux/store';


interface ButtonProps {
    text: HeaderButtonEnum;
    icon: string;
}

const HeaderButton = ({ text, icon }: ButtonProps) => {
    const dispatch = useDispatch();
    const productsCounter = useSelector((state: RootState) => state.shop.products.length);

    const isProductsCounterVisible = (): boolean => {
        return text === HeaderButtonEnum.SHOP_BAG && productsCounter > 0;
    }

    const handleShopBasketOpen = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.SHOP_BASKET));
        dispatch(toggleModalOpen());
    }

    const handleOpenLoginModal = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.SIGN_IN));
        dispatch(toggleModalOpen());
    }

    const handleOpenUserPanel = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.ACCOUNT_PANEL));
        dispatch(toggleModalOpen());
    }

    const handleLogOut = (): void => {
        deleteCookies();
        dispatch(signOutUser());
    }

    const deleteCookies = (): void => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }

    const buttonActions = {
        [HeaderButtonEnum.SHOP_BAG]: handleShopBasketOpen,
        [HeaderButtonEnum.SIGN_IN]: handleOpenLoginModal,
        [HeaderButtonEnum.USER_ACCOUNT]: handleOpenUserPanel,
        [HeaderButtonEnum.SIGN_OUT]: handleLogOut
    }
    return (
        <div className="header-button" onClick={ buttonActions[text] }>
            <img className="ico" src={ icon } alt={`${text} icon`} />
            <h4 className="btn-txt">{ text }</h4>
            { isProductsCounterVisible() ? <span className="shop-basket-counter">{productsCounter}</span> : '' }
        </div>
    )
}

export default HeaderButton
