import React from 'react'

const MessageSend = () => {
    return (
        <div className="order-pre-confirmation">
            <h2 className="order-pre-confirmation__title">Wiadomość wysłana</h2>
            <div className="order-pre-confirmation__payment-info">
                <h2 className="order-info">Wiadomość została poprawnie wysłana. Odpowiem na nią jak najszybciej to możliwe.</h2>
            </div>
        </div>
    )
}

export default MessageSend
