import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenuOpen } from '../../../redux/layoutSlice';
import { RootState } from '../../../redux/store';

const HamburgerMenuButton = () => {

    const isMenuOpen = useSelector((state: RootState) => state.layout.isMenuOpen);
    const dispatch = useDispatch();

    const handleMenuClick = (): void => {
        const body = document.querySelector('body');

        if (!isMenuOpen) {
            body!.style.overflow = 'hidden';
        } else {
            body!.style.overflow = 'auto';
        }

        dispatch(toggleMenuOpen());
    }

    return (
        <div className={`hamburger-wrapper ${isMenuOpen ? 'hamburger-z-index' : ''}`} onClick={ handleMenuClick }>
            <div className={ `hamburger ${isMenuOpen ? 'menu-visible' : ''}` }>
                <span className="hamburger__inner"></span>
            </div>
        </div>
    )
}

export default HamburgerMenuButton
