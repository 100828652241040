import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.scss';
import Backdrop from './shared-components/Backdrop/Backdrop';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import ContactPage from './pages/ContactPage/ContactPage';
import AboutMePage from './pages/AboutMePage/AboutMePage';
import RecipesPage from './pages/RecipesPage/RecipesPage';
import RecipeViewPage from './pages/RecipeViewPage/RecipeViewPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ShopPage from './pages/ShopPage/ShopPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import Modal from './shared-components/Modal/Modal';
import OrderRealizationPage from './pages/OrderRealizationPage/OrderRealizationPage';
import OfferSingleProductPage from './pages/OfferSingleProductPage/OfferSingleProductPage';
import { signInUser, signOutUser } from './redux/authSlice';

function App() {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state: RootState) => state.layout.isModalOpen);

  useEffect(() => {
	getTokenCookie();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTokenCookie() {
      // eslint-disable-next-line no-useless-concat
      const match = document.cookie.match(new RegExp('(^| )' + 'token' + '=([^;]+)'));
      if (match) {
        dispatch(signInUser());
      } else {
		dispatch(signOutUser());
	  }
   }

  return (
    <div className={`app-container ${isModalOpen ? 'overflow-hidden' : ''}`}>
      <BrowserRouter>
        <Header />
          <ScrollToTop>
            <HamburgerMenu />
            <Routes>
              <Route path="/" element={ <HomePage/> } />
              <Route path="/about" element={ <AboutMePage/> } />
              <Route path="/recipes" element={ <RecipesPage/> } />
              <Route path="/recipes/:recipeId" element={ <RecipeViewPage/> } />
              <Route path="/shop" element={ <ShopPage/> } />
              <Route path="/contact" element={ <ContactPage/> } />
              <Route path="/orderRealization" element={ <OrderRealizationPage/> } />
              <Route path="/productView/:id" element={ <OfferSingleProductPage /> } />
              <Route path="*" element={ <Navigate to="/" /> } />
            </Routes>
            { isModalOpen ? <Modal /> : ''}
          </ScrollToTop>
        <Footer />
      </BrowserRouter>
      <Backdrop />
    </div>
  );
}

export default App;
