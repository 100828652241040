import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toggleMenuOpen } from '../../redux/layoutSlice';
import { RootState } from '../../redux/store';
import logo from '../../utils/assets/ZiP-logo.svg';

const HamburgerMenu = () => {
    const isMenuOpen = useSelector((state: RootState) => state.layout.isMenuOpen);
    const dispatch = useDispatch();

    const handleLinkClick = (): void => {
        const body = document.querySelector('body');

        if (!isMenuOpen) {
            body!.style.overflow = 'hidden';
        } else {
            body!.style.overflow = 'auto';
        }

        dispatch(toggleMenuOpen());
    }
    return (
        <div className={`hamburger-menu-wrapper ${isMenuOpen ? 'visible' : ''}`}>
            <div className="hamburger-menu">
                <img src={ logo } alt="Logo" className="hamburger-menu__logo" />
                <nav className="hamburger-menu__nav">
                    <ul>
                        <li onClick={ handleLinkClick }>
                            <NavLink to="/" className={ ({isActive}) => isActive ? 'active-link' : '' }>STRONA GŁÓWNA</NavLink>
                        </li>
                        <li onClick={ handleLinkClick }>
                            <NavLink to="/about" className={ ({isActive}) => isActive ? 'active-link' : '' }>O MNIE</NavLink>
                        </li>
                        <li onClick={ handleLinkClick }>
                            <NavLink to="/recipes" className={ ({isActive}) => isActive ? 'active-link' : '' }>PRZEPISY</NavLink>
                        </li>
                        <li onClick={ handleLinkClick }>
                            <NavLink to="/shop" className={ ({isActive}) => isActive ? 'active-link' : '' }>SKLEP</NavLink>
                        </li>
                        <li onClick={ handleLinkClick }>
                            <NavLink to="/contact" className={ ({isActive}) => isActive ? 'active-link' : '' }>KONTAKT</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default HamburgerMenu
