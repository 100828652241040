import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { User } from '../../model/User.model'
import { signInUser } from '../../redux/authSlice'
import { toggleModalOpen } from '../../redux/layoutSlice'
import Button from '../../shared-components/Button/Button'

interface Response {
    code: number;
    accessToken: string;
}

const SignIn = () => {
    const dispatch = useDispatch();

    const [userForm, setUserForm] = useState<User>(new User("", ""));
    const [emailErr, setEmailErr] = useState<boolean>(false);
    const [passwordErr, setPasswordErr] = useState<boolean>(false);

    const [signInSuccess, setSignInSuccess] = useState<boolean>(true);
    const [signInUnhandledError, setSignInUnhandledError] = useState<boolean>(false);

    const handleInputChange = (e: any) => {
        e.persist()
        setUserForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    
    const handleSignIn = (): void => {
        if (isFormValidatedOk().length < 1) {
            signIn();
        }
    }

    const signIn = async () => {
        await fetch('/api/v1/signIn', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(userForm)
        })
        .then(resp => resp.json())
        .then((resp: Response ) => {
            if (resp.code === 1001) {
                setSignInSuccess(false);
            } else if (resp.code === 2000) {
                setSignInSuccess(true);
                setJWTAuthCookie(resp.accessToken);
				setUserEmailCookie(userForm.email);
                dispatch(signInUser())
                dispatch(toggleModalOpen())
            } else {
                setSignInSuccess(false);
                setSignInUnhandledError(true);
            }
        })
    }

    const setJWTAuthCookie = (token: string): void => {
        document.cookie = `token=${token};`;
    }

	const setUserEmailCookie = (email: string): void => {
		document.cookie = `user=${email}`;
	}

    const isFormValidatedOk = (): string[] => {
        const errors = [];

        if (userForm.email.length < 1) {
            errors.push('emailErr')
            setEmailErr(true);
        } else {
            setEmailErr(false);
        }

        if (userForm.password.length < 1) {
            errors.push('passwordErr')
            setPasswordErr(true);
        } else {
            setPasswordErr(false);
        }

        return errors
    }

    useEffect(() => {
        isFormValidatedOk();
        //eslint-disable-next-line
    }, [userForm])

    return (
        <div className="sign-in">
            <h2 className="sign-in__title">Logowanie</h2>
            <form className="sign-in__form">
                <label htmlFor="email">E-mail</label>
                <input onChange={ handleInputChange } className={ emailErr ? 'error' : '' } name="email" id="email" type="email" placeholder="E-mail..." />
                <label htmlFor="password">Hasło</label>
                <input onChange={ handleInputChange } className={ passwordErr ? 'error' : '' } name="password" id="password" type="password" placeholder="Hasło..." />
                <div className="error-box">
                    { !signInSuccess && !signInUnhandledError ? <span className="sign-in-error">Błędny e-mail lub hasło!</span> : '' }
                    { !signInSuccess && signInUnhandledError ? <span className="sign-in-error">Wystapił błąd. Spróbuj ponownie później!</span> : '' }
                </div>  
                <Button text={ ButtonTextEnum.SIGN_IN } action={ handleSignIn } />
            </form>           
        </div>
    )
}

export default SignIn
