import React from 'react'
import { Link } from 'react-router-dom';
import { ButtonTextEnum } from '../../../enums/Button/ButtonText.enum'
import { Recipe } from '../../../model/Recipe.model';
import Button from '../../../shared-components/Button/Button'

interface SingleRecipeProps {
    recipe: Recipe;
    isActive?: boolean;
}

const SingleRecipe = ({ recipe, isActive }: SingleRecipeProps) => {

    return (
        <div className="latest-recipe">
            <div className={`latest-recipe-content ${isActive ? 'active' : ''}`}>
                <h3 className="latest-recipe-content__title">{ recipe.title }</h3>
                <p className="latest-recipe-content__text">{ recipe.description }</p>
                <Link to={`/recipes/${recipe.id}`}>
                    <Button text={ ButtonTextEnum.READ_MORE } small />
                </Link>
            </div>
            <img className="latest-recipe-content__photo" src={ recipe.photo1 } alt="Recipe" />
        </div>
    )
}

export default SingleRecipe
