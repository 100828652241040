import React, { MouseEventHandler, useEffect, useState } from 'react'
import { ButtonSizeEnum } from '../../enums/Button/ButtonSize.enum';

interface ButtonProps {
    small?: boolean;
    medium?: boolean;
    big?: boolean;
    text: string;
    action?: MouseEventHandler<any> 
}

const Button = ({ small, medium, big, text, action }: ButtonProps) => {

    const [size, setSize] = useState<string>("");

    const changeSize = (): void => {
        if (small) {
            setSize(ButtonSizeEnum.SMALL)
        }
        if (medium) {
            setSize(ButtonSizeEnum.MEDIUM)
        }
        if (big) {
            setSize(ButtonSizeEnum.BIG)
        }
    }

    useEffect(() => {
        changeSize();
        //eslint-disable-next-line
    }, [])

    return (
        <button type="button" className={ `button ${size}` } onClick={ action }>
            { text }
        </button>
    )
}

export default Button
