import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum'
import { setModalContentType, toggleModalOpen } from '../../redux/layoutSlice'
import Button from '../../shared-components/Button/Button'
import { cleanShopState } from '../../redux/shopSlice';
import { RootState } from '../../redux/store';
import { BasketProduct } from '../../model/BasketProduct.model';

interface ContactDetails {
    fullName: string;
    email: string;
}

const OrderPreConfirmation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state = useSelector((state: RootState) => state.shop)

    const [contactDetails, setContactDetails] = useState<ContactDetails>({
        fullName: "",
        email: "",
    })

    const [accountCreate, setAccountCreate] = useState<boolean>(false)
    const [canCreateAccount, setCanCreateAccount] = useState<boolean>(true);
    const [canSendOrder, setCanSendOrder] = useState<boolean>(false); 

    const createObjectForOwnerToSend = () => {
        const object = {
            customerName: contactDetails.fullName,
            customerEmail: contactDetails.email,
            products: state.products.map(el => {
                return {
                    productTitle: el.shopProduct.title,
                    dietLength: el.dietLength,
                    interview: el.interview,
                    pairInterviews: el.pairInterviews,
                    price: el.price
                }
            }),
            fullPrice: getFullPrice(state.products),
        }

        return object;
    }

    const createObjectForCustomer = () => {
        const object = {
            customerName: contactDetails.fullName,
            customerEmail: contactDetails.email,
            fullPrice: getFullPrice(state.products),
            productsTitles: getProductsTitles(state.products)
        }

        return object;
    }

    const getProductsTitles = (products: BasketProduct[]): string[] => {
        const titlesArr: string[] = [];

        products.forEach(el => titlesArr.push(el.shopProduct.title));

        return titlesArr;
    }

    const getFullPrice = (products: BasketProduct[]): number => {
        let fullPrice = 0

        products.forEach(el => {
            fullPrice += parseInt(el.price);
        })

        return fullPrice
    }

    const handleCancel = (): void => {
        dispatch(toggleModalOpen());
    }

    const handleInputChange = (e: any) => {
        e.persist()
        setContactDetails(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleSelectChange = (e: any) => {
        e.persist();
        setAccountCreate(e.target.checked)
    }

    const isFieldRequired = (fieldName: string): boolean => {
        if (fieldName === "email") {
            return !validateEmail();
        } else {
            return contactDetails[fieldName as keyof ContactDetails].length < 1
        }
    }

    const validateEmail = (): boolean => {
        const emailRegExp = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        return emailRegExp.test(contactDetails.email);
    }

    const handleSendOrder = async () => {
        if (validateEmail() && (contactDetails.fullName.length > 0)) {
            if (accountCreate) {
                await createAccount();
            } else {
				setCanSendOrder(true);
            }
        }
    }

    const sendOrder = () => {
        if (canSendOrder) {
            sendOrderToCustomer();
            sendOrderToOwner();
            navigate('/');
            dispatch(setModalContentType(ModalContentTypeEnum.ORDER_CONFIRMATION))
            dispatch(cleanShopState());
        }
    }

    useEffect(() => {
        sendOrder();
        //eslint-disable-next-line
    }, [canSendOrder])

    const createAccount = async () => {
        const email = {
            email: contactDetails.email
        }

        await fetch('/api/v1/createAccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(email)
        })
        .then((resp) => resp.json())
        .then((resp: {code: number}) => {
            if (resp.code === 1000) {
                setCanCreateAccount(false);
            } else {
                setCanCreateAccount(true);
                setCanSendOrder(true);
            }
        })
    } 

    const sendOrderToCustomer = async () => {
        const customerObj = createObjectForCustomer();

        await fetch('/api/v1/sendToCustomer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(customerObj)
        })
        .then((resp) => {
            return resp
        })
    }

    const sendOrderToOwner = async () => {
        const ownerObj = createObjectForOwnerToSend();

        await fetch('/api/v1/sendToOwner', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(ownerObj)
        })
        .then((resp) => {
            return resp
        })
    }

    const openPrivacyPolicy = () => {
        dispatch(setModalContentType(ModalContentTypeEnum.PRIVACY_POLICY));
    }

    const openShopRules = () => {
        dispatch(setModalContentType(ModalContentTypeEnum.SHOP_RULES));
    }

    return (
        <div className="order-pre-confirmation">
            <h2 className="order-pre-confirmation__title">Potwierdzenie zamówienia</h2>
            <h3 className="order-pre-confirmation__info">
                Aby wysłać zamówienie podaj dane kontaktowe
            </h3>
            <form>
                <div className="input-wrapper">
                    <label htmlFor="fullName">Imię i nazwisko</label>
                    <input className={ isFieldRequired("fullName") ? 'required-field' : '' } onChange={ handleInputChange } type="text" name="fullName" id="fullName" value={ contactDetails.fullName } />
                    <span></span>
                </div>
                <div className="input-wrapper">
                    <label htmlFor="email">E-mail</label>
                    <input className={ isFieldRequired("email") ? 'required-field' : '' } onChange={ handleInputChange } type="email" name="email" id="email" value={ contactDetails.email } />
                    <span></span>
                    { canCreateAccount ? "" : <span className="error-message">Taki użytkownik już istnieje!</span> }
                </div>
            </form>
            <div className="account-question-box">
                <div className="checkbox">
                    <input onChange={ handleSelectChange } type="checkbox" id="account-create" name="account-create" />
                    <label htmlFor="account-create">Chcę założyć darmowe konto z&nbsp;dostępem do panelu klienta.</label>
                </div>
            </div>
            <div className="order-pre-confirmation__rules-info">
                Klikając "WYŚLIJ" potwierdzasz, że zapoznałeś się z <span onClick={ openPrivacyPolicy }>polityką prywatności</span> oraz <span onClick={ openShopRules }>regulaminem sklepu</span> i akceptujesz wymienione w nich postanowienia.
            </div>
            <div className="order-pre-confirmation__buttons-wrapper">
                <Button text={ ButtonTextEnum.CANCEL } action={ handleCancel } />
                <Button text={ ButtonTextEnum.SEND_ORDER } action={ handleSendOrder } />
            </div>
        </div>
    )
}

export default OrderPreConfirmation
