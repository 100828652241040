import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import { RootState } from '../../redux/store'
import OrderProductsSummaryTable from '../../shared-components/OrderProductsSummaryTable/OrderProductsSummaryTable'
import OrderProductsTable from '../../shared-components/OrderProductsTable/OrderProductsTable'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'

const OrderRealizationPage = () => {
    const products = useSelector((state: RootState) => state.shop.products);
	const navigate = useNavigate();

	useEffect(() => {
		if (products.length < 1) {
			navigate('/');
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (
        <SubPageWrapper title={ PagesTitleEnum.ORDER_REALIZATION }>
            <div className="order-realization-page">
                <div className="order-realization-page__content-wrapper">
                    <OrderProductsTable products={ products } orderRealization={ true }/>
                    <OrderProductsSummaryTable products={ products } orderRealization={ true } />
                </div>
            </div>
        </SubPageWrapper>
    )
}

export default OrderRealizationPage
