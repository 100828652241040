import { ProductTypeEnum } from "../enums/Shop/ProductType/ProductType.enum";

export class InterviewModel {
    type?: ProductTypeEnum;
    fullName?: string;
    email?: string;
    phoneNumber?: string;
    birthDate?: string;
    visitPurpose?: string;
    weight?: string;
    height? : string;
    waist?: string;
    chest?: string;
    hips?: string;
    thighs?: string;
    arm?: string
    pregnant?: string;
    breastFeeding?: string;
    diseaseUnits?: string;
    medicines?: string;
    foodIntolerance?: string;
    physicalActivity?: string;
    wakeUpHours?: string;
    goSleepHours?: string;
    preferredNumberOfMeals?: string
    eatingHours?: string;
    dislikedFood?: string;
    likedFood?: string;
    drinks?: string;
    additionalInformations?: string;
    glucose?: string;
    cholesterol?: string;
    ldl?: string;
    hdl?: string;
    trigliceryds?: string;
    tsh?: string;
    liverTests?: string;
    others?: string;

    constructor(type?: ProductTypeEnum) {
        this.type = type;
    }
}