//eslint-disable-next-line
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import layoutReducer from './layoutSlice';
import shopReducer from './shopSlice';
import authReducer from './authSlice';

const store = configureStore({ 
    reducer: {
        layout: layoutReducer,
        shop: shopReducer,
        auth: authReducer
    },
	devTools: false,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store;