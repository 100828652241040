import React from 'react'

const InterviewsNotFilled = () => {
    return (
        <div className="order-pre-confirmation">
            <h2 className="order-pre-confirmation__title">Wystąpił błąd</h2>
            <h3 className="order-pre-confirmation__info realization-error">
                Wybrane produkty wymagają wypełnienia ankiety. Zamknij to okienko i&nbsp; wypełnij ankiety do wybranych produktów klikając "WYPEŁNIJ".
            </h3>
        </div>
    )
}

export default InterviewsNotFilled
