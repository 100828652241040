import { InterviewModel } from "./Interview.model";
import { ShopProduct } from "./ShopProduct.model";

export class BasketProduct {
    basketProductUUID: string;
    shopProduct: ShopProduct;
    dietLength: number;
    price: string;
    interview: InterviewModel;
    isInterviewFilled: boolean;
    pairInterviews: InterviewModel[];
    firstPersonPairInterviewFilled: boolean;
    secondPersonPairInterviewFilled: boolean;
    firstPersonPairInterviewEditFilled: boolean;


    constructor(basketProductUUID: string, shopProduct: ShopProduct, dietLength: number, price: string, interview: InterviewModel, isInterviewFilled: boolean, firstPersonPairInterviewFilled?: boolean, secondPersonPairInterviewFilled?: boolean) {
        this.basketProductUUID = basketProductUUID;
        this.shopProduct = shopProduct;
        this.dietLength = dietLength;
        this.price = price;
        this.interview = interview;
        this.pairInterviews = [];
        this.isInterviewFilled = isInterviewFilled;
        this.firstPersonPairInterviewFilled = false;
        this.secondPersonPairInterviewFilled = false;
        this.firstPersonPairInterviewEditFilled = false;
    }
}