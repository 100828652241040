export enum ButtonTextEnum {
    SEE_MORE = "DOWIEDZ SIĘ WIĘCEJ",
    READ_MORE = "CZYTAJ WIĘCEJ",
    FULL_OFFER = "PEŁNA OFERTA",
    SEND_MESSAGE = "WYŚLIJ",
    ADD_PRODUCT = "DODAJ DO KOSZYKA",
    BACK_TO_SHOP = "WRÓC DO SKLEPU",
    GOT_TO_BASKET = "IDŹ DO KOSZYKA",
    GO_TO_IMPLEMENTATION = "IDŹ DO REALIZACJI",
    FILL_INTERVIEW = "WYPEŁNIJ",
    EDIT_INTERVIEW = "EDYTUJ",
    SAVE = "ZAPISZ",
    IMPLEMENT = "ZREALIZUJ",
    CANCEL = "ANULUJ",
    SEND_ORDER = "WYŚLIJ",
    SIGN_IN = "ZALOGUJ SIĘ",
    DELETE_ACCOUNT = "USUŃ KONTO",
}