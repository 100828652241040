import React from 'react'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import Button from '../Button/Button'
import { Recipe } from '../../model/Recipe.model';
import { Link } from 'react-router-dom';

interface Props {
    recipe: Recipe;
}

const SingleRecipe = ({ recipe }: Props) => {
    return (
        <div className="single-recipe">
            <div className="recipe-info">
                <h2 className="recipe-info__title">{ recipe.title }</h2>
                <p className="recipe-info__description">{ recipe.description }</p>
                <Link to={`/recipes/${recipe.id}`}>
                    <Button text={ ButtonTextEnum.READ_MORE } small />
                </Link>
            </div>
            <img className="recipe-photo" src={ recipe.photo1 } alt="Recipe" />
        </div>
    )
}

export default SingleRecipe
