import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum'
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import { setModalContentType, toggleModalOpen } from '../../redux/layoutSlice'
import Button from '../../shared-components/Button/Button'
import SocialMedia from '../../shared-components/SocialMedia/SocialMedia'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'

interface Contact {
    name: string;
    email: string;
    message: string;
}

interface Response {
    code: number;
}

const ContactPage = () => {
    const [form, setForm] = useState<Contact>({
        name: '',
        email: '',
        message: ''
    }); 

    const [messageSend, setMessageSend] = useState<boolean>(false);
    const [messageSendError, setMessageSendError] = useState<boolean>(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const openPrivacyPolicy = () => {
        dispatch(setModalContentType(ModalContentTypeEnum.PRIVACY_POLICY));
        dispatch(toggleModalOpen());
    }

    const handleInputChange = (e: any) => {
        e.persist()
        setForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const isFieldRequired = (fieldName: string): boolean => {
        if (fieldName === "email") {
            return !validateEmail();
        } else {
            return form[fieldName as keyof Contact].length < 1
        }
    }

    const validateEmail = (): boolean => {
        const emailRegExp = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

        return emailRegExp.test(form.email);
    }

    const handleSubmitForm = () => {
        if (validateEmail() && form.name.length > 0 && form.message.length > 0) {
            sendQuestion();
        }
    }

    useEffect(() => {
        if (messageSend) {
            navigate('/');
            dispatch(setModalContentType(ModalContentTypeEnum.MESSAGE_SEND))
            dispatch(toggleModalOpen())
        }
        //eslint-disable-next-line
    }, [messageSend])

    const sendQuestion = async () => {
        await fetch('/api/v1/sendQuestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(form)
        })
        .then((resp) => resp.json())
        .then((resp: Response) => {
            if (resp.code === 2002) {
                setMessageSend(true);
            } else {
                setMessageSendError(true);
            }
        })
        .catch(err => setMessageSendError(true));
    }

    return (
        <SubPageWrapper title={ PagesTitleEnum.CONTACT_PAGE } additionalClassName="contact-page-wrapper">
            <div className="contact-page ">
                <h2 className="contact-page__title">Masz do mnie pytanie?</h2>
                <h2 className="contact-page__title">Skontaktuj się ze mną za pomocą poniższego formularza lub napisz maila.</h2>
                <div className="contact-options-wrapper">
                    <form className="contact-form">
                        <div className="input-wrapper">
                            <input type="text" name="name" className={isFieldRequired("name") ? 'required-field' : ''} onChange={ handleInputChange } placeholder="Twoje imie..." />
                            <span></span>
                        </div>
                        <div className="input-wrapper">
                            <input type="text" name="email" className={isFieldRequired("email") ? 'required-field' : ''} onChange={ handleInputChange } placeholder="Twój e-mail..." />
                            <span></span>                
                        </div>
                        <div className="input-wrapper">
                            <textarea name="message" className={isFieldRequired("message") ? 'required-field' : ''} onChange={ handleInputChange } placeholder="Twoja wiadomość..." />
                            <span></span>
                        </div>
                        <div className="send-message-info">
                            { messageSend ? <p>Wiadomość została wysłana</p> : '' }
                            { messageSendError ? <p>Błąd wysyłania. Spróbuj ponownie później</p> : '' }
                        </div>
                        <div className="submit-box">
                            <div className="checkbox">
                                <p className="rules-box__text">Naciskając "WYŚLIJ" oświadczam, że przeczytałem i&nbsp;akceptuję <span onClick={ openPrivacyPolicy }>politykę&nbsp;prywatności.</span></p>
                            </div>
                            <Button text={ ButtonTextEnum.SEND_MESSAGE } small action={ handleSubmitForm } />
                        </div>
                    </form>
                    <div className="contact-details-wrapper">
                        <div className="contact-details">
                            <h3 className="contact-details__title">Dane kontaktowe:</h3>
                            <p className="contact-details__data">Natalia Galus</p>
                            <p className="contact-details__data">
                                Tel.: <a href="tel:+48502877270">+48 502 877 270</a>
                            </p>
                            <p className="contact-details__data">
                                Mail.: <a href="mail:natalia@zdrowoipozytywnie.pl">natalia@zdrowoipozytywnie.pl</a>
                            </p>
                            <h3 className="contact-details__title">Moje socialmedia:</h3>
                            <div className="socialmedia-box">
                                <SocialMedia />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SubPageWrapper>
    )
}

export default ContactPage
