import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper';
import { Recipe } from '../../model/Recipe.model';

interface ParamTypes {
    recipeId: string;
    recipeTitle: string;
}

interface Response {
    code: number;
    content: Recipe;
}

const RecipeViewPage = () => {
    const { recipeId } = useParams() as ParamTypes;
    const navigate = useNavigate();

    const [recipe, setRecipe] = useState<Recipe>()

    const getRecipeById = async(id: string) => {
        await fetch("https://zdrowoipozytywnie.pl/api/v1/getRecipe/" + id)
            .then(resp => resp.json())
            .then((resp: Response) => {
                if (resp.content) {
                    const cleanedLists = resp.content.list.map(el => {
                        const str = JSON.stringify(el);
                        const cleanedStr = str.replace(/\\/g, '').slice(1, -1);
                        return JSON.parse(cleanedStr)
                    })

                    const recipe = resp.content;
                    recipe.list = cleanedLists;

                    setRecipe(recipe);
                } else {
                    redirectToHome();
                }
            });
    }

    const redirectToHome = (): void => {
        navigate('/')
    }

    useEffect(() => {
        if (recipeId) {
            getRecipeById(recipeId);
        }
        //eslint-disable-next-line
    }, [recipeId])

    return (
        <>
        { recipe ?
        <SubPageWrapper title={ recipe.title }>
            <div className="recipe-view">
                <div className="row recipe img-desc">
                    <div className="recipe__image-wrapper">
                        <img className="recipe__main-img" src={ recipe.photo1 } alt="Food" />
                    </div>
                    <h2 className="recipe__title">{ recipe.title }</h2>
                    <p className="recipe__description">
                        { recipe.description }
                    </p>
                </div>
                <div className="row recipe">
                    { recipe.list.map((el, idx1) => (
                        <div key={ idx1 + 'parent' } className="recipe__ingredients-wrapper">
                            <h3 className="recipe__ingredients-title">{ el.listTitle }</h3>
                            <ul className="recipe__ingredients">
                                { el.listElements.map((liEl, idx2) => (
                                    <li key={ idx2 + 'child' } className="ingredient">{ liEl }</li>
                                )) }
                            </ul>
                        </div>
                        )) 
                    }
                </div>
                <div className="row recipe img-desc">
                    <p className="recipe__description bottom-desc">
                        { recipe.subDescription }
                    </p>
                    { recipe.photo2.length > 0 ? 
                        <div className="recipe__image-wrapper bottom-image">
                            <img className="recipe__main-img" src={ recipe.photo2 } alt="Food" />
                        </div>
                        :
                        ""
                    }
                </div>
            </div>
        </SubPageWrapper>
        :
        ""
        }
        </>
    )
}

export default RecipeViewPage
