import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import OrderProductsSummaryTable from '../../shared-components/OrderProductsSummaryTable/OrderProductsSummaryTable';
import OrderProductsTable from '../../shared-components/OrderProductsTable/OrderProductsTable';

const ShopBasket = () => {
    const products = useSelector((state: RootState) => state.shop.products);

    return (
        <div className="shop-basket">
            <h2 className="shop-basket__title">Twój koszyk</h2>
            <div className="shop-basket__content-wrapper">
                <OrderProductsTable products={ products } orderRealization={ false }/>
                <OrderProductsSummaryTable products={ products } orderRealization={ false } />
            </div>
        </div>
    )
}

export default ShopBasket
