import React from 'react'

const ShopRules = () => {
    return (
        <div className="privacy-policy">
            <h1 className="privacy-policy__title">REGULAMIN SPRZEDAŻY DLA UMÓW ZAWIERANYCH NA ODLEGŁOŚĆ</h1>
            <p className="privacy-policy__sub-title">
                Niniejszy Regulamin określa zasady składania zamówień, dokonywania zakupu produktów oraz usług, w tym świadczenia usług drogą elektroniczną oraz korzystania z publikacji elektronicznych za pomocą sklepu internetowego prowadzonego pod adresem www.zdrowoipozytywnie.pl.
            </p>
            <h2 className="privacy-policy__list-title">§1 Definicje</h2>
            <ul>
                <li>Sprzedawca – Natalia Galus, zamieszkała pod adresem Ładna 175b, 33-156 Skrzyszów, woj. małopolskie</li>
                <li>Klient – osoba fizyczna, osoba prawna lub jednostka organizacyjna nie posiadająca osobowości prawnej, która składa zamówienie w sklepie internetowym.</li>
                <li>Konsument – osoba fizyczna dokonująca ze Sprzedawcą czynności prawnej (zawarcia umowy) niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową</li>
                <li>Sklep internetowy – serwis internetowy prowadzony przez Sprzedawcę dostępny pod adresem www. zdrowoipozytywnie.pl, umożliwiający zakup towarów i usług oraz świadczenie usług drogą elektroniczną, a także korzystanie z publikacji elektronicznych za pomocą sieci Internet www. zdrowoipozytywnie.pl</li>
                <li>Produkt – towar wytworzony i przeznaczony do sprzedaży za pośrednictwem sklepu internetowego.</li>
                <li>Usługa – usługi świadczone przez Sprzedawcę zarówno w sposób bezpośredni, jak i drogą elektroniczną, dostępne za pośrednictwem sklepu internetowego.</li>
                <li>Publikacja elektroniczna – dostępne za pośrednictwem sklepu internetowego treści cyfrowe (pliki elektroniczne) zawierające treści, obrazy, zdjęcia, dźwięki, stanowiące utwory w rozumieniu ustawy z dnia 04 lutego 1994 r. o prawie autorskim i prawach pokrewnych, do których prawa majątkowe przysługują Sprzedawcy tj. m.in. e-booki.</li>
                <li>Zamówienie – złożone za pośrednictwem sklepu internetowego oświadczenie woli Klienta zmierzające, zależnie od przedmiotu zamówienia, bezpośrednio do zawarcia umowy sprzedaży, umowy na świadczenie usług bądź umowy licencyjnej.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§2 Postanowienia ogólne</h2>
            <ul>
                <li>Regulamin określa warunki i zasady sprzedaży produktów, korzystania z publikacji elektronicznych oraz świadczenia przez Sprzedawcę usług obejmujących w szczególności konsultacje online oraz przygotowywanie indywidualnych planów dietetycznych.</li>
                <li>Klient korzystając ze sklepu internetowego potwierdza, że zapoznał się z treścią niniejszego Regulaminu oraz że akceptuje jego postanowienia.</li>
                <li>Sklep internetowy objęty jest autorskimi prawa majątkowymi. Oznacza to, że wszystkie elementy tekstowe, graficzne, dźwiękowe, jak również układy elementów i formularze stanowią prawa majątkowe i podlegają ochronie zgodnie z ustawą z dnia 04 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§3 Warunki techniczne</h2>
            <ul>
                <li>1. Do korzystania z witryny sklepu internetowego, dokonywania zakupu produktów i usług oraz pobierania publikacji elektronicznych, niezbędne jest spełnianie następujących wymogów technicznych:</li>
                <ul>
                    <li>posiadanie aktywnego konta poczty elektronicznej;</li>
                    <li>dostęp do urządzenia posiadającego odpowiednio skonfigurowaną i aktualną przeglądarkę internetową umożliwiającą przeglądanie stron WWW wraz z obsługą plików Cookies i skryptów Java Script oraz obsługę poczty elektronicznej;</li>
                    <li>dostęp i połączenie z siecią Internet (wszystkie ewentualne koszty ponosi Klienta);</li>
                </ul>
                <li>
                    Sprzedawca informuje, że dokłada wszelkich starań, aby korzystanie ze sklepu internetowego i świadczonych usług oraz publikacji elektronicznych było możliwe na wszystkich popularnych typach systemów operacyjnych i przeglądarek internetowych, jednakże zastrzega, iż ze względów technologicznych, mogą czasami występować utrudnienia w korzystaniu ze sklepu internetowego, oferowanych usługach i publikacji elektronicznych.
                </li>
                <li>
                    Klienci mogą uzyskać pomoc techniczną związaną z problemami podczas korzystania ze sklepu internetowego poprzez kontakt drogą elektroniczną na adres natalia@zdrowoipozytywnie.pl.
                </li>
            </ul>
            <h2 className="privacy-policy__list-title">§4 Warunki formalne</h2>
            <ul>
                <li>Klientem może być:</li>
                <ul>
                    <li>osoba fizyczna, która ukończyła 18 lat i posiada pełną zdolność do czynności prawnych;</li>
                    <li>osoba fizyczna, która ukończyła 16 lat i posiada zgodę opiekuna prawnego na zawarcie umowy;</li>
                    <li>osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej;</li>
                </ul>
                <li>Usługi obejmujące konsultacje online oraz indywidualne plany dietetyczne dostępne są wyłącznie dla Klientów, którzy przed przystąpieniem do korzystania z usług ustalili z właściwym lekarzem, iż brak jest przeciwwskazań do stosowania diety, a nadto konsultacje takie prowadzą także na bieżąco w trakcie korzystania z usług.</li>
                <li>Usługi świadczone przez Sprzedawcę przeznaczone są wyłącznie na użytek własny Klientów. Klienci nie mogą ich wykorzystywać w części lub całości do jakichkolwiek innych celów, a w szczególności o charakterze doradczym lub informacyjnym dla osób trzecich, a także porównawczych lub poglądowych.</li>
                <li>Klient zobowiązany jest do korzystania ze sklepu internetowego zgodnie z obowiązującym Regulaminem, obowiązującymi przepisami prawa oraz dobrymi obyczajami.</li>
                <li>Klientowi korzystającemu ze sklepu internetowego nie wolno:</li>
                <ul>
                    <li>nadużywać środków komunikacji elektronicznej w sposób powodujący utratę stabilności pracy lub przeciążenie systemów teleinformatycznych bezpośrednio lub pośrednio zaangażowanych przy prowadzeniu sklepu internetowego, świadczeniu usług, korzystaniu z publikacji elektronicznych;</li>
                    <li>upubliczniać materiałów lub informacji, do których przysługują Sprzedawcy prawa majątkowe;</li>
                </ul>
            </ul>
            <h2 className="privacy-policy__list-title">§5 Zamówienia</h2>
            <ul>
                <li>Zamówienia za pośrednictwem sklepu internetowego można składać dwadzieścia cztery godziny na dobę przez siedem dni w tygodniu.</li>
                <li>W celu złożenia zamówienia Klient musi dokonać niżej wskazanych czynności:</li>
                <ul>
                    <li>wybór jednej lub kilku pozycji produktów, usług lub publikacji elektronicznych poprzez opcję „dodaj do koszyka”;</li>
                    <li>wybór polecenia „idź do kasy”; „idź do realizacji” oraz „zrealizuj” i „wyślij”;</li>
                    <li>wypełnienie elektronicznego formularza zamówienia w sposób kompletny i zgodny z faktami;</li>
                    <li>wysłanie zamówienia w formie elektronicznej poprzez wybór polecenia „Kupuję i płacę”;</li>
                    <li>wybór metody i dokonanie płatności;</li>
                </ul>
                <li>Klient składając zamówienie zobowiązany jest do zamieszczenia w formularzu zamówienia wszystkich danych pozwalających na jego identyfikację oraz nawiązanie kontaktu, w szczególności numeru telefonu oraz adresu poczty elektronicznej.</li>
                <li>Po prawidłowym złożeniu zamówienia Klient otrzyma wiadomość elektroniczną potwierdzającą przyjęcia zamówienia. Potwierdzenie przyjęcia zamówienia może zostać dokonane także poprzez potwierdzenie telefoniczne. Brak potwierdzenia przyjęcia zamówienia oznacza, że zamówienie nie zostało przyjęte do realizacji.</li>
                <li>Sprzedawca zastrzega sobie prawo do weryfikacji złożonego zamówienia pod względem jego prawidłowości i rzetelności, a także prawo do jego anulowania w przypadku powzięcia uzasadnionych wątpliwości, co do jego prawidłowości lub rzetelności.</li>
                <li>Jeżeli zamówienie złożone przez Klienta zawiera braki lub jest niekompletne, Sprzedawca skontaktuje się z Klientem wykorzystując informacje kontaktowe podane w formularzu zamówienia, w celu usunięcia braków lub wątpliwości. W przypadku niemożności skontaktowania się z Klientem lub nieusunięcia dostrzeżonych braków, Sprzedawcą uprawniony jest do anulowania zamówienia.</li>
                <li>W przypadku nieopłacenia zamówienia w terminie 7 dni Sprzedawca anuluje zamówienie.</li>
                <li>Każde złożone zamówienie otrzymuje indywidualny numer, pozwalający na identyfikację Klienta oraz przedmiotu zamówienia.</li>
                <li>W efekcie złożenia prawidłowego zamówienia przez Klienta, potwierdzenia zamówienia przez Sprzedawcę oraz zapłaty ceny dochodzi do zawarcia pomiędzy stronami umowy, na warunkach określonych niniejszym Regulaminem. Zawarcie umowy potwierdzane jest wiadomością elektroniczną, zawierającą informację o wszystkich istotnych elementach zamówienia.</li>
                <li>W przypadku niemożności zrealizowania zamówienia w terminie określonym Regulaminem Sprzedawca niezwłocznie powiadomi Klienta o zaistniałej sytuacji, wskazując inny termin realizacji zamówienia. Klient może zaakceptować nowy termin realizacji zamówienia bądź też odmówić akceptacji i zażądać zwrotu dokonanych płatności. Wyjątek stanowi przerwa świąteczna występująca w dniach od 24.12.2019 do 6.01.2019.</li>
                <li>Sprzedawca nie ponosi odpowiedzialności za opóźnienia wywołane zdarzeniami nadzwyczajnymi, takimi jak:</li>
                <ul>
                    <li>przerwy w dostawie energii, spowodowane nie z winy Sprzedawcy,</li>
                    <li>przerwy w dostępie do Internetu, spowodowane nie z winy Sprzedawcy,</li>
                    <li>inne zdarzenia losowe, spowodowane nie z winy Sprzedawcy.</li>
                </ul>
            </ul>
            <h2 className="privacy-policy__list-title">§6 Cechy i metody płatności</h2>
            <ul>
                <li>Wszystkie ceny produktów, usług i publikacji elektronicznych dostępnych na stronie sklepu internetowego są wyrażone w złotych polskich i są cenami brutto. W przypadku potwierdzenia przez Sprzedawcę przyjęcia zamówienia ceną wiążącą Klienta jest cena podana podczas składania zamówienia na podstronie „Koszyk”.</li>
                <li>Klient może dokonać zapłaty za złożone zamówienie korzystając z następujących form płatności:</li>
                <ul>
                    <li>przelew tradycyjny na rachunek bankowy Sprzedawcy: Natalia Galus, Ładna 175B, 33-156 Skrzyszów. Numer konta: 30 1240 1910 1111 0010 4269 1070</li>
                </ul>
                <li>Skorzystanie z wybranego przez Klienta systemu płatności online wymaga uprzedniego zapoznania się i zaakceptowania regulaminu obowiązującego u dostawcy płatności oraz postępowania według zasad określonych w tym regulaminie.</li>
                <li>W związku z realizacją złożonego zamówienia Sprzedawca wystawia paragon lub fakturę VAT. W przypadku wyboru faktury VAT Klient wyraża zgodę na wystawienie faktury VAT w wersji elektronicznej w formacie PDF i dostarczenia jej na wskazany w zamówieniu adres poczty elektronicznej.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§7 Realizacja zamówienia</h2>
            <ul>
                <li>Sprzedawca przystąpi do realizacji zamówienia po otrzymaniu płatności lub otrzymaniu potwierdzenia dokonania płatności online, w sposób określony w ofercie zamieszczonej w sklepie internetowym, z uwzględnieniem informacji umieszczonych przez Klienta w formularzu elektronicznym.</li>
                <li>Zamówienie jest realizowane w terminie od 1 do 10 dni roboczych.</li>
                <li>W przypadku zamówienia usług lub treści cyfrowych realizacja zamówienia odbywa się zależnie od rodzaju zamówionej usługi/treści cyfrowej:</li>
                <ul>
                    <li>w odniesieniu do indywidualnych planów dietetycznych realizacja zamówienia odbywa się za pośrednictwem sieci Internet, poprzez wysłanie na adres wskazanej przez Klienta poczty elektronicznej wiadomości zawierającej zamówione treści w formacie PDF. Zamówione treści będą też dostępne w Panelu Klienta na stronie www.zdrowoipozytywnie.pl.</li>
                    <li>w odniesieniu do zamówienia publikacji elektronicznej realizacja zamówienia odbywa się za pośrednictwem sieci Internet, poprzez wysłanie na adres wskazanej przez Klienta poczty elektronicznej wiadomości zawierającej zamówione treści w formacie PDF. Zamówione treści będą też dostępne w Panelu Klienta na stronie www.zdrowoipozytywnie.pl.</li>
                </ul>
            </ul>
            <h2 className="privacy-policy__list-title">§8 Wymagania techniczne do korzystania z publikacji elektronicznych</h2>
            <ul>
                <li>Każda publikacja elektroniczna opatrzona jest informacją o dostępnym formacie, w jakim doszło do zapisania informacji w pliku (np. PDF, MOBI, EPUB, MP3).</li>
                <li>Przed zamówieniem publikacji elektronicznej Klient powinien sprawdzić czy urządzenie elektroniczne, na którym chce otworzyć plik zawierający publikację, obsługuje format w jakim publikacja została zapisana. Sprzedawca nie gwarantuje (nie ponosi w tym względzie żadnej odpowiedzialności), że publikacja elektroniczna otworzy się na każdym urządzeniu elektronicznym, przy użyciu każdego programu posiadającego funkcję obsługi danego formatu pliku.</li>
                <li>W przypadku problemów z dostępem do publikacji elektronicznej Klient może skontaktować się ze Sprzedawcą drogą elektroniczną na adres natalia@zdrowoipozytywnie.pl.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§9 Licencja</h2>
            <ul>
                <li>Wszystkie publikacje elektroniczne dostępne w sklepie internetowym objęte są autorskim prawami majątkowymi.</li>
                <li>Klient zamawiając publikację elektroniczną zawiera umowę licencyjną, w ramach której otrzymuje niewyłączną, odpłatną licencję na korzystanie z publikacji elektronicznej przez czas nieoznaczony, zezwalającą Klientowi na pobranie i zapisanie publikacji elektronicznej na własnych nośnikach pamięci.</li>
                <li>Klient nie jest uprawniony do rozpowszechniania zamówionych publikacji elektronicznych w jakikolwiek sposób wykraczający poza zakres użytku osobistego. Tym samym jakiekolwiek powielanie, wyświetlanie, wypożyczanie, publiczne pokazy czy też inne rozpowszechnianie całości lub fragmentów publikacji elektronicznej w jakikolwiek sposób jest zabronione.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§10 Postępowanie reklamacyjne</h2>
            <ul>
                <li>Klient uprawniony jest do zgłoszenia reklamacji dotyczącej przebiegu transakcji lub niezgodności zamówionego produktu, usługi lub publikacji elektronicznej ze złożonym zamówieniem.</li>
                <li>Zgłoszenie reklamacji powinno nastąpić niezwłocznie, nie później jednak niż w terminie 7 dni od zaistnienia zdarzenia objętego reklamacją.</li>
                <li>Zgłoszenie reklamacyjne składane jest za pośrednictwem poczty elektronicznej na adres e-mail natalia@zdrowoipozytywnie.pl.</li>
                <li>Zgłoszenie reklamacyjne musi zawierać co najmniej: adres poczty elektronicznej podany przy składaniu zamówienia, numer zamówienia, przyczynę reklamacji, proponowany sposób rozpatrzenia reklamacji, dane kontaktowe. W przypadku zgłoszenia reklamacji niezawierającej wszystkich ww. informacji, Sprzedawca uprawniony jest do wezwania Klienta do jej uzupełnienia pod rygorem odmowy rozpatrzenia reklamacji.</li>
                <li>Sprzedawca ustosunkuje się do reklamacji w terminie 14 dni roboczych od prawidłowego złożenia reklamacji.</li>
                <li>W przypadku pozytywnego rozpatrzenia reklamacji Sprzedawca usunie przyczynę reklamacji w terminie 14 dni roboczych.</li>
            </ul>
            <h2 className="privacy-policy__list-title">§11 Odstąpienie od umowy</h2>
            <ul>
                <li>Klientowi będącemu konsumentem (określanego w tym paragrafie jako konsument), z zastrzeżeniem ust. 11, przysługuje prawo do odstąpienia od umowy zawartej na odległość, bez podania przyczyny i bez ponoszenia kosztów, z wyjątkiem kosztów określonych w art. 33 i art. 34 ust. 2 ustawy o prawach konsumenta.</li>
                <li>Termin odstąpienia od umowy zawartej na odległość wynosi 14 dni od momentu wydania rzeczy, a do zachowania terminu wystarczy wysłanie oświadczenia przed jego upływem w formie pisemnej na adres poczty elektronicznej natalia@zdrowoipozytywnie.pl.</li>
                <li>Sprzedawca niezwłocznie potwierdzi konsumentowi na adres poczty elektronicznej, podany przy składaniu zamówienia lub inny, jeżeli został podany w złożonym oświadczeniu, otrzymanie oświadczenia o odstąpieniu od umowy.</li>
                <li>W przypadku odstąpienia od umowy, umowa jest uważana za niezawartą.</li>
                <li>Konsument ma obowiązek zwrócić Sprzedawcy rzecz będącą przedmiotem umowy niezwłocznie, jednak nie później niż w terminie 14 dni od dnia odstąpienia od umowy. Do zachowania terminu wystarczy odesłanie rzeczy przed jego upływem.</li>
                <li>Konsument odsyła rzecz będącą przedmiotem umowy, od której odstąpił, na własny koszt i ryzyko. Postanowienie to nie dotyczy treści elektronicznych udostępnianych przez Internet.</li>
                <li>Konsument ponosi odpowiedzialność za zmniejszenie wartości rzeczy stanowiącej przedmiot umowy, będące wynikiem korzystania z niej w sposób wykraczający poza zakres konieczny do stwierdzenia charakteru, cech i funkcjonowania rzeczy.</li>
                <li>Sprzedawca niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania oświadczenia o odstąpieniu od umowy, zwróci konsumentowi wszystkie dokonane przez niego płatności, w tym koszty dostarczenia rzeczy, a jeżeli konsument wybrał sposób dostawy inny niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę, to Sprzedawca nie zwróci konsumentowi poniesionych przez niego dodatkowych kosztów.</li>
                <li>Sprzedawca dokonuje zwrotu dokonanych płatności przy użyciu takiego samego sposobu płatności, jakiego użył konsument, chyba że konsument określił inny sposób zwrotu, który jest dostępny i nie wiąże się żadnymi kosztami.</li>
                <li>Sprzedawca może wstrzymać się ze zwrotem płatności otrzymanej od konsumenta do chwili otrzymania rzeczy z powrotem lub dostarczenia przez konsumenta dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.</li>
                <li>Konsumentowi nie przysługuje prawo do odstąpienia od umowy:</li>
                <ul>
                    <li>o świadczenie usług, jeżeli Sprzedawca wykonał w pełni usługę za wyraźną zgodą konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedawcę utraci prawo odstąpienia od umowy;</li>
                    <li>w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji konsumenta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;</li>
                    <li>w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                    <li>w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                    <li>o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez Sprzedawcę o utracie prawa do odstąpienia od umowy;</li>
                    <li>o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę.</li>
                </ul>
            </ul>
            <h2 className="privacy-policy__list-title">§12 Postanowienia końcowe</h2>
            <ul>
                <li>Regulamin wchodzi w życie z dniem 11.01.2022 r.</li>
                <li>Sprzedawca zastrzega sobie prawo do zmiany postanowień Regulaminu z ważnych przyczyn, a w szczególności ze względu na zmiany w obowiązujących przepisach prawa, zamiany zasad świadczenia usług, zmiany organizacyjne Sprzedawcy, podyktowanych względami technicznymi.</li>
                <li>Zmiana postanowień Regulaminu obowiązuje od momentu ich opublikowania na stronie sklepu internetowego i znajduje zastosowanie do zamówień złożonych po wejściu zmian w życie.</li>
                <li>W sprawach nieuregulowanych w Regulaminie zastosowanie znajdują przepisy prawa polskiego, a w szczególności Kodeksu Cywilnego oraz ustawy o prawach konsumenta.</li>
                <li>Wszelkie spory powstałe w związku z korzystaniem przez Klienta ze sklepu internetowego i składanymi w nim zamówieniami rozstrzygane będą przez sąd właściwy miejscowo dla Sprzedawcy.</li>
                <li>Jeżeli którekolwiek z postanowień Regulaminu okaże się lub zostanie uznane prawomocnym orzeczeniem sądu za nieważne, pozostałe postanowienia Regulaminu pozostają w mocy.</li>
            </ul>



        </div>
    )
}

export default ShopRules
