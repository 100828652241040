import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum';
import { setModalInterviewNotEdit, toggleModalOpen } from '../../redux/layoutSlice';
import { RootState } from '../../redux/store';
import ProductAdded from '../../components/ProductAdded/ProductAdded';
import ShopBasket from '../../components/ShopBasket/ShopBasket';
import CooperationInterview from '../../components/CooperationInterview/CooperationInterview';
import OrderPreConfirmation from '../../components/OrderPreConfirmation/OrderPreConfirmation';
import OrderConfirmation from '../../components/OrderConfirmation/OrderConfirmation';
import InterviewsNotFilled from '../../components/InterviewsNotFilled/InterviewsNotFilled';
import { setFirstPersonPairInterviewEditNotFilled } from '../../redux/shopSlice';
import SignIn from '../../components/SignIn/SignIn';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import ShopRules from '../../components/ShopRules/ShopRules';
import MessageSend from '../../components/MessageSend/MessageSend';
import AccountPanel from '../../components/AccountPanel/AccountPanel';

const Modal = () => {
    const dispatch = useDispatch();
    const modalContentType = useSelector((state: RootState) => state.layout.modalContentType);

    const handleModalClose = (): void => {
        dispatch(toggleModalOpen())
        dispatch(setFirstPersonPairInterviewEditNotFilled())
        dispatch(setModalInterviewNotEdit());
    }

    const modalContentTypeComponent = {
        [ModalContentTypeEnum.PRODUCT_ADDED]: <ProductAdded />,
        [ModalContentTypeEnum.SHOP_BASKET]: <ShopBasket />,
        [ModalContentTypeEnum.INTERVIEW]: <CooperationInterview />,
        [ModalContentTypeEnum.ORDER_PRE_CONFIRMATION]: <OrderPreConfirmation />,
        [ModalContentTypeEnum.ORDER_CONFIRMATION]: <OrderConfirmation />,
        [ModalContentTypeEnum.INTERVIEWS_NOT_FILLED]: <InterviewsNotFilled />,
        [ModalContentTypeEnum.SIGN_IN]: <SignIn />,
        [ModalContentTypeEnum.PRIVACY_POLICY]: <PrivacyPolicy />,
        [ModalContentTypeEnum.SHOP_RULES]: <ShopRules />,
        [ModalContentTypeEnum.MESSAGE_SEND]: <MessageSend />,
        [ModalContentTypeEnum.ACCOUNT_PANEL]: <AccountPanel />
    }

    return (
        <div className="modal-wrapper">
            <div className="modal">
                <div className="close-modal-wrapper" onClick={ handleModalClose }>
                    <span className="close-modal"></span>
                </div>
                { modalContentTypeComponent[modalContentType!] }
            </div>
        </div>
    )
}

export default Modal;
