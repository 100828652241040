export enum ModalContentTypeEnum {
    SHOP_BASKET,
    PRODUCT_ADDED,
    INTERVIEW,
    INTERVIEWS_NOT_FILLED,
    ORDER_PRE_CONFIRMATION,
    ORDER_CONFIRMATION,
    SIGN_IN,
    PRIVACY_POLICY,
    SHOP_RULES,
    MESSAGE_SEND,
    ACCOUNT_PANEL
}