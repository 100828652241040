import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { PagesTitleEnum } from '../../enums/Pages/PagesTitle.enum'
import { ProductTypeEnum } from '../../enums/Shop/ProductType/ProductType.enum'
import { ShopTabsTitleEnum } from '../../enums/Shop/ShopTabsTitle/ShopTabsTitle.enum'
import { ShopProduct } from '../../model/ShopProduct.model'
import ProductShopView from '../../shared-components/ProductShopView/ProductShopView'
import SubPageWrapper from '../../shared-components/SubPageWrapper/SubPageWrapper'

const ShopPage = () => {
    const [products, setProducts] = useState<ShopProduct[]>();

    const getProducts = async() => {

        await fetch("https://zdrowoipozytywnie.pl/api/v1/getProducts")
            .then(resp => resp.json())
            .then(products => setProducts(products.content));
    }

    // const addProduct = async (body: any) => {
    //     await fetch('https://zdrowoipozytywnie.pl/api/v1/addProduct', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //         },
    //         body: JSON.stringify(body)
    //     })
    //     .then((resp) => resp.json())
    //     .then((res) => console.log(res));
    // }

    const getSortedProducts = (products: ShopProduct[]) => {
        return products.sort((a, b) => a.product_order - b.product_order)
    }

    const getIndividualProducts = (productsList: ShopProduct[]): ShopProduct[] => {
        return productsList.filter(product => product.type === ProductTypeEnum.INDIVIDUAL_NEW || product.type === ProductTypeEnum.INDIVIDUAL_CONTINUATION);
    }

    const getPairProducts = (productsList: ShopProduct[]): ShopProduct[] => {
        return productsList.filter(product => product.type === ProductTypeEnum.PAIR_NEW || product.type === ProductTypeEnum.PAIR_CONTINUATION);
    }

    useEffect(() => {
        getProducts();
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <SubPageWrapper title={ PagesTitleEnum.SHOP_PAGE }>
                <div className="shop-page">
                    <Tabs>
                        <TabList>
                            {/* <Tab>{ ShopTabsTitleEnum.READY_DIETS }</Tab> */}
                            <Tab>{ ShopTabsTitleEnum.INDIVIDUAL_DIETS }</Tab>
                            <Tab>{ ShopTabsTitleEnum.PAIR_DIETS }</Tab>
                            {/* <Tab>{ ShopTabsTitleEnum.E_BOOKS }</Tab> */}
                        </TabList>
                        <TabPanel>
                            <div className="tab-content-wrapper">
                                { products ? getSortedProducts(getIndividualProducts(products)).map((el, idx) => (
                                    <ProductShopView key={ idx } product={ el } />
                                )) : ''}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="tab-content-wrapper">
                                { products ? getSortedProducts(getPairProducts(products)).map((el, idx) => (
                                    <ProductShopView key={ idx } product={ el } />
                                )) : ''}
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </SubPageWrapper>
        </>
    )
}

export default ShopPage; 