import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum';
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum';
import { BasketProduct } from '../../model/BasketProduct.model';
import { InterviewModel } from '../../model/Interview.model';
import { ShopProduct, Price } from '../../model/ShopProduct.model';
import { toggleModalOpen, setModalContentType } from '../../redux/layoutSlice';
import { addProductToBasket } from '../../redux/shopSlice';
import Button from '../Button/Button';

interface Props {
    product: ShopProduct
}

const ProductShopView = ({ product }: Props) => {
    const { id, img, title, sub_title, diet_length, price  } = product;

    const dispatch = useDispatch();

    const [selectedDietLength, setSelectedDietLength] = useState<number>(7);

    const handleAddProduct = (): void => {
        dispatch(setModalContentType(ModalContentTypeEnum.PRODUCT_ADDED));
        dispatch(addProductToBasket(createNewBasketProduct()));
        dispatch(toggleModalOpen());
    }

    const createNewBasketProduct = (): BasketProduct => {
        return new BasketProduct(createBasketProductUUID(product.id), product, selectedDietLength, getPrice(), new InterviewModel(), false)
    }

    const createBasketProductUUID = (id: string): string => {
        return id + (Math.random() * 16).toString();
    }

    const getPrice = (): string => {
        return price[selectedDietLength as keyof Price]
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDietLength(parseInt(e.target.value));
    }

    return (
        <div className="single-product-shop-view">
            <img src={ img } className="single-product-shop-view__img" alt="Product" />
            <div className="single-product-shop-view__content-wrapper">
                <h3 className="single-product-shop-view__title">{ title }</h3>
                <p className="single-product-shop-view__description">{ sub_title }</p>
                <div className="single-product-shop-view__actions-wrapper">
                    <div className="single-product-shop-view__diet-length-action">
                        <h4>Długość diety:</h4>
                        <div className="select-dropdown">
                            <select value={ selectedDietLength } onChange={ handleSelectChange }>
                                { diet_length.map((el, idx) => (
                                    <option value={ el } key={ idx }>{ el }</option>
                                )) }
                            </select>
                        </div>
                    </div>
                    <div className="single-product-shop-view__add-action">
                        <span className="price">{ getPrice() }zł</span>
                        <Button text={ ButtonTextEnum.ADD_PRODUCT } action={ handleAddProduct } />
                        <Link to={`/productView/${ id }`}>
                            <Button text={ ButtonTextEnum.READ_MORE } />
                        </Link>
                    </div>
                </div>
            </div>             
        </div>
    )
}

export default ProductShopView
