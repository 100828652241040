import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalContentTypeEnum } from '../enums/Modal/ModalContentType.enum';
import { ProductTypeEnum } from '../enums/Shop/ProductType/ProductType.enum';

export interface LayoutState {
    isMenuOpen: boolean,
    isBackdropVisible: boolean,
    isModalOpen: boolean,
    modalContentType?: ModalContentTypeEnum,
    modalInterviewType?: ProductTypeEnum,
    isInterviewModalEdit?: boolean
}

const initialState: LayoutState = {
    isMenuOpen: false,
    isBackdropVisible: false,
    isModalOpen: false,
    isInterviewModalEdit: false,
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        toggleMenuOpen: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
            state.isBackdropVisible = !state.isBackdropVisible;
        },
        toggleModalOpen: (state) => {
            state.isModalOpen = !state.isModalOpen;
            state.isBackdropVisible = !state.isBackdropVisible;
        },
        setModalContentType: (state, action: PayloadAction<ModalContentTypeEnum>) => {
            state.modalContentType = action.payload
        },
        setModalInterviewType: (state, action: PayloadAction<ProductTypeEnum>) => {
            state.modalInterviewType = action.payload
        },
        setModalInterviewEdit: (state) => {
            state.isInterviewModalEdit = true
        },
        setModalInterviewNotEdit: (state) => {
            state.isInterviewModalEdit = false;
        }
    },
})

export const { toggleMenuOpen, toggleModalOpen, setModalContentType, setModalInterviewNotEdit, setModalInterviewType, setModalInterviewEdit } = layoutSlice.actions;

export default layoutSlice.reducer;