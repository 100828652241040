//eslint-disable-next-line
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = ({ children }: any) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return children || null
}

export default ScrollToTop;