import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { User } from '../../model/User.model'
import { signOutUser } from '../../redux/authSlice'
import { toggleModalOpen } from '../../redux/layoutSlice'
import Button from '../../shared-components/Button/Button'

interface Response {
    code: number;
}

const AccountPanel = () => {
    const dispatch = useDispatch();
    const [deleteForm, setDeleteForm] = useState<User>(new User("", ""));
    const [deleteAccountError, setDeleteAccountError] = useState<boolean>(false);
	const [userDiets, setUserDiets] = useState<any>([]);

    const handleInputChange = (e: any) => {
        e.persist()

        setDeleteForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleDeleteAccount = () => {
        if (deleteForm.email.length > 0 && deleteForm.password.length > 0) {
            deleteAccount();
        }
    }

    const deleteAccount = async () => {
        await fetch('/api/v1/deleteAccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token')
            },
            body: JSON.stringify(deleteForm)
        })
        .then(resp => resp.json())
        .then((resp: Response) => {
            if (resp.code === 2001) {
                logOutUser();
            } else {
                setDeleteAccountError(true);
            }
        })
		.catch(e => {
			console.error(e);
		})
    }

    const logOutUser = () => {
        deleteToken();
        dispatch(signOutUser());
        dispatch(toggleModalOpen());
    }

    const getCookie = (cookieName: string): string | null => {
        const cookiesArr = document.cookie.split(';');
    
        for(let i = 0; i < cookiesArr.length; i++) {
            let cookieNameValue = cookiesArr[i].split('=');
            if (cookieNameValue[0].trim() === cookieName) {
                return decodeURIComponent(cookieNameValue[1]);
            }
        }
        return null;
    }

    const deleteToken = (): void => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

	const getUserDiet = async () => {
		await fetch('/api/v1/getUserDiet', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getCookie('token')
            },
            body: JSON.stringify({
				email: getCookie('user')
			})
        })
        .then(resp => resp.json())
        .then((resp: any) => {
			setUserDiets(resp.diets)
        })
	}

	useEffect(() => {
		getUserDiet();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const dietsExists = (): boolean => {
		if (userDiets === null) {
			return false;
		} else if (userDiets.length < 1) {
			return false;
		} else if (userDiets.length > 0) {
			return true;
		} else {
			return false;
		}
	}

    return (
        <div className="account-panel">
            <h3 className="account-panel__title">Panel klienta</h3>
            <Tabs>
                <TabList>
                    <Tab>TWOJE DIETY</Tab>
                    <Tab>ZARZĄDZANIE KONTEM</Tab>
                </TabList>
                <TabPanel>
                    <div className="tab-content-wrapper">
						{ dietsExists() ?
							<ul className='diet-urls'>
								{ userDiets.map((el: any, idx: any) => (
									<li>
										<a href={`https://zdrowoipozytywnie.pl/uploadedDiets/${el.diet_file_name}`} target="_blank" rel='noreferrer'>Kliknij tutaj aby pobrać dietę:</a>
										<p>{el.diet_file_name}</p>
									</li>
								)) }
							</ul>
							:
                        	<p className="empty-diets-info">Tutaj pojawi się Twoja dieta.</p>
						}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="tab-content-wrapper">
                        <div className="account-actions-box">
                            <h3 className="account-actions-box__title">Usuwanie konta:</h3>
                            <div className="account-actions-box__data">
                                <form className="action-form">
                                    <div className="input-wrapper">
                                        <label htmlFor="password">Wprowadź dane aby usunąć konto</label>
                                        <input type="email" onChange={ handleInputChange } value={ deleteForm.email } id="email" name="email" placeholder="E-mail..." /> 
                                        <input type="password" onChange={ handleInputChange } value={ deleteForm.password } id="password" name="password" placeholder="Hasło..." /> 
                                    </div>
                                    <Button text={ButtonTextEnum.DELETE_ACCOUNT} action={ handleDeleteAccount } />
                                    { deleteAccountError ? <span className="delete-account-error">Błędny e-mail lub hasło!</span> : '' }
                                </form>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default AccountPanel
