import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ButtonTextEnum } from '../../enums/Button/ButtonText.enum'
import { ModalContentTypeEnum } from '../../enums/Modal/ModalContentType.enum'
import { BasketProduct } from '../../model/BasketProduct.model'
import { setModalContentType, toggleModalOpen } from '../../redux/layoutSlice'
import Button from '../Button/Button'

interface Props {
    products: BasketProduct[],
    orderRealization: boolean,
}

const OrderProductsSummaryTable = ({ products, orderRealization }: Props) => {
    const dispatch = useDispatch();

    const getFullPrice = (): number => {
        let fullPrice = 0

        products.forEach(el => {
            fullPrice += parseInt(el.price);
        })

        return fullPrice
    }

    const handleGoToRealization = (): void => {
        dispatch(toggleModalOpen());
    }

    const checkIfProductsInterviewFilled = (): boolean => {
        return Object.values(products).every(el => el.isInterviewFilled)
    }

    const showPreConfirmation = (): void => {
        if (checkIfProductsInterviewFilled()) {
            dispatch(setModalContentType(ModalContentTypeEnum.ORDER_PRE_CONFIRMATION))
        } else {
            dispatch(setModalContentType(ModalContentTypeEnum.INTERVIEWS_NOT_FILLED))
        }
        dispatch(toggleModalOpen());
    }

    return (
        <div className="summary">
            <ul className="heading">
                <li>Podsumowanie</li>
            </ul>
            <div className="body summary-info-wrapper">
                <p className="summary-info-wrapper__title">CAŁOŚĆ:</p>
                <p className="summary-info-wrapper__price">{ getFullPrice() }zł</p>
            </div>
            { products.length > 0 && !orderRealization ?
                <Link to="/orderRealization">
                    <Button text={ ButtonTextEnum.GO_TO_IMPLEMENTATION } action={ handleGoToRealization } /> 
                </Link>
                :
                ''
            }
            { orderRealization ? 
                <Button text={ ButtonTextEnum.IMPLEMENT } action={ showPreConfirmation } /> 
                :
                ''
            }
        </div>
    )
}

export default OrderProductsSummaryTable
