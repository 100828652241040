import React from 'react'
import { useLocation } from 'react-router'
import { PagesPathEnum } from '../../enums/Pages/PagesPath.enum';

interface SubPageWrapperProps {
    title: string;
    additionalClassName?: string;
    children: any;
}

const SubPageWrapper = ({ title, additionalClassName, children }: SubPageWrapperProps) => {
    const location = useLocation();

    const isShopPageActive = (): string => {
        return location.pathname === PagesPathEnum.SHOP ? 'shop-background' : '';
    }

    return (
        <main className="sub-page-main">
            <div className="page-title-box container">
                <h1 className="title">{ title }</h1>
            </div>
            <div className={ `container ${additionalClassName}` }>
                <div className={ `sub-page-main__background ${isShopPageActive()}` }>
                    { children }
                </div>
            </div>
        </main>
    )
}

export default SubPageWrapper
