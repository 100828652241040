import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

const Backdrop = () => {
    const isBackdropVisible = useSelector((state: RootState) => state.layout.isBackdropVisible);

    return (
        <div className={ `backdrop ${isBackdropVisible ? 'visible' : ''}` } />
    )
}

export default Backdrop
