import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1 className="privacy-policy__title">Polityka prywatności</h1>
            <p className="privacy-policy__sub-title">
                Publikujemy ten dokument w celu wyjaśnienia powodów, dla których gromadzimy i przetwarzamy dane osobowe w ramach prowadzonej przez nas działalności:
            </p>
            <h2 className="privacy-policy__list-title">1. Co to są dane osobowe?</h2>
            <p className="privacy-policy__list-content">
                Są to wszystkie informacje, które pozwalają bez większego wysiłku odróżnić jedną osobę od pozostałych. Mogą one dotyczyć zarówno wprost tej osoby (takie jak imię i nazwisko, numer identyfikacyjny, a czasem nawet adres e-mail lub konto internetowe), jak też takie, które nie opisują jej bezpośrednio. Przykładowo dotyczą jej cech, stanu zdrowia, poglądów, miejsca zamieszkania, nałogów, rasy lub wyznania.
            </p>
            <h2 className="privacy-policy__list-title">2. O jakich danych osobowych mówimy w naszym przypadku?</h2>
            <p className="privacy-policy__list-content">
                Przetwarzamy dane, które przekazują nam nasi Klienci, Kontrahenci oraz Pracownicy, w związku z korzystaniem z naszych usług, współpracą z nami lub zatrudnieniem.
            </p>
            <h2 className="privacy-policy__list-title">3. Co to znaczy przetwarzanie danych?</h2>
            <p className="privacy-policy__list-content">
                Przetwarzanie to wszelkie czynności jakie możemy wykonać z danymi osobowymi – związane zarówno z ich aktywnym wykorzystaniem,  takim jak  gromadzenie,  pobieranie, utrwalanie, łączenie, modyfikowanie czy też udostępnianie, jak też pasywnym, takim jak przechowywanie, ograniczanie, usuwanie lub niszczenie.
            </p>
            <h2 className="privacy-policy__list-title">4. Kto jest Administratorem Danych (to znaczy kto ma wpływ na ich przetwarzanie oraz bezpieczeństwo)?</h2>
            <p className="privacy-policy__list-content">
                Administratorami Twoich danych jest Natalia Galus,  woj. małopolskie, pow. tarnowski, gm. Skrzyszów, miejsc. Ładna 175B, kod 33-156, poczta Skrzyszów.
            </p>
            <h2 className="privacy-policy__list-title">5. Na jakiej podstawie prawnej i w jakim celu przetwarzamy Twoje dane?</h2>
            <p className="privacy-policy__list-content">
                Każde przetwarzanie Twoich danych musi być oparte na właściwej, zgodnej z obowiązującymi przepisami, podstawie prawnej.  Podstawą taką może być Twoja zgoda na przetwarzanie danych lub inne zapisy prawne pozwalające na to zawarte w Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (określanym jako „RODO”) lub w różnych aktach prawa krajowego, takich jak ustawy lub rozporządzenia.
            </p>
            <h2 className="privacy-policy__list-header">Twoje dane mogą być przez nas przetwarzane w kilku różnych celach, przykładowo:</h2>
            <ul>
                <li>
                    Swoje dane możesz nam udostępnić wysyłając maila, Twoje dane przetwarzamy wówczas w oparciu o udzieloną przez Ciebie zgodę, którą wyrażasz automatycznie w chwili, gdy wysyłasz do nas swoje dane (np. adres mailowy). Twoja zgoda jest dobrowolna – pamiętaj, że możesz w każdej chwili ją cofnąć. W takim przypadku niezwłocznie usuniemy wszystkie podane przez Ciebie informacje, o ile tylko nie zostałeś naszym Klientem.
                </li>
                <li>
                    Jeżeli jesteś naszym Klientem lub osobą zainteresowaną skorzystaniem ze świadczonych przez nas usług, Twoje dane przetwarzamy w związku z zawartą z Tobą umową lub w ramach przygotowań do zawarcia takiej umowy. Odbywa się to zawsze za Twoją wiedzą i wolą. Wyrażając zamiar zawarcia umowy, wiesz jakie dane osobowe będą potrzebne do jej podpisania, a po jej podpisaniu masz wiedzę, jakie dane w tym celu przekazałeś lub przekażesz w okresie późniejszym.
                </li>
                <li>
                    Możemy też przetwarzać Twoje dane w związku z koniecznością zapewnienia bezpieczeństwa naszej sieci informatycznej oraz informacji. Nastąpi to wówczas, gdy będziesz korzystał z naszej infrastruktury informatycznej lub łączył się z nią, np. wchodząc na naszą stronę lub przesyłając do nas wiadomości. Jest to nasz prawnie uzasadniony interes.
                </li>
                <li>
                    Jeżeli jesteś zainteresowany podjęciem pracy u nas, Twoje dane przetwarzane są w postaci nadesłanej przez Ciebie aplikacji lub CV. Odbywa się to za Twoją wiedzą i pisemną zgodą, którą możesz w każdej chwili cofnąć. W takim przypadku Twoja kandydatura nie zostanie przez nas rozpatrzona oraz niezwłocznie usuniemy wszystkie podane przez Ciebie dane. Natomiast z chwilą zatrudnienia się u nas, dalsze zasady przetwarzania danych oraz obowiązkowy zakres ich przekazania i przechowywania określają przepisy prawa pracy.
                </li>
            </ul>
            <h2 className="privacy-policy__list-title">6. Komu przekazujemy Twoje dane?</h2>
            <p className="privacy-policy__list-content">
                Zgodnie z obowiązującym prawem Twoje dane możemy przekazywać podmiotom przetwarzającym je na nasze zlecenie, np. takim jak: operator pocztowy, biuro rachunkowe, dostawcy usług informatycznych lub inni wskazani w zawartej z Tobą umowie podwykonawcy naszych usług. Mamy też obowiązek udostępnić je także na żądanie podmiotów uprawnionym do tego na podstawie innych przepisów prawa (np. sądy lub organy ścigania). Udostępnienie nastąpi jednak tylko wtedy, gdy zwrócą się one do nas z wnioskiem w tej sprawie, wskazując podstawę prawną, które im  na takie żądanie zezwala.Nie przewidujemy przekazywania Twoich danych do państw trzecich lub organizacji międzynarodowych, to jest poza obszar gospodarczy Unii Europejskiej. Na terenie Unii Europejskiej, dzięki RODO, masz zapewniony identyczny poziom ochrony Twoich danych w wszystkich krajach członkowskich. 
                Tekst RODO jest odstępny pod adresem: <a href="http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=OJ:L:2016:119:TOC">http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=OJ:L:2016:119:TOC</a>
            </p>
            <h2 className="privacy-policy__list-title">7. Jak długo będziemy przetwarzać Twoje dane?</h2>
            <p className="privacy-policy__list-content">
                Przykładamy bardzo dużą uwagę do tego, aby ograniczyć do niezbędnego minimum zakres gromadzonych przez nas danych, jak też czas ich przetwarzania. W tym celu wykonujemy systematyczne przeglądy posiadanych dokumentów papierowych i elektronicznych, usuwając zbędne, których czas przydatności minął. Pamiętaj, że o czasie przetwarzania Twoich danych, zależnie od podstawy, w oparciu o którą je uzyskaliśmy, mogą decydować odrębne – niezależne od nas przepisy prawne, które mogą nakładać na nas obowiązek przechowywania Twoich danych, niezależnie od Twojej woli lub chęci. Przykładem może być prawo pracy, prawo ubezpieczeń społecznych czy przepisy dotyczące rachunkowości.
                Jeśli skorzystałeś osobiście z naszych usług i zawarliśmy w tej sprawie umowę, zgodnie z przepisami dotyczącymi rachunkowości, będziemy posiadać Twoje dane w wytworzonej w związku z tym dokumentacji finansowo-księgowej i przetwarzać przez okres 5 kolejnych lat kalendarzowych, licząc od daty zakupu/zawarcia umowy.
                Jeśli posiadane przez nas dane miałyby zostać wykorzystane w inny celu, niż w jakim zostały one pozyskane, zawsze zostaniesz przez nas o tym poinformowany i będziesz mógł się temu sprzeciwić.
            </p>
            <h2 className="privacy-policy__list-title">8. Jakie masz prawa w stosunku do Twoich danych?</h2>
            <h2 className="privacy-policy__list-header">Jeśli przetwarzamy Twoje dane osobowe, zawsze masz prawo do:</h2>
            <ul>
                <li>żądania dostępu do danych – w granicach art. 15 RODO,</li>
                <li>ich sprostowania – w granicach art. 16 RODO,</li>
                <li>żądania usunięcia – w granicach art. 17 RODO,</li>
                <li>lub ograniczenia czynności przetwarzania – w granicach art. 18 RODO,</li>
                <li>wniesienia sprzeciwu wobec przetwarzania danych – w granicach art. 21 RODO,</li>
                <li>przenoszenia danych, w tym uzyskania ich kopii – w granicach art. 20 RODO.</li>
            </ul>
            <p className="privacy-policy__list-content">
                Wszystkie te prawa szczegółowo zostały omówione w art. 15 do 21 RODO, którego tekst jest odstępny pod adresem: <a href="http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=OJ:L:2016:119:TOC">http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=OJ:L:2016:119:TOC</a>
            </p>
            <p className="privacy-policy__list-content">
                Możesz także wycofać swoją zgodę na przetwarzanie danych osobowych, w takim przypadku niezwłocznie usuniemy Twoje dane osobowe, jeśli tylko nie wystąpi obowiązek prawny nakazujący nam ich dalsze przetwarzanie.  Przykładowo jeśli zażądasz usunięcia swojego adresu mailowego w związku z rezygnacją z newslettera, niezwłocznie skasujemy go  z bazy mailingowej.
            </p>
            <p className="privacy-policy__list-content">
                Jeśli uznasz, że w jakikolwiek sposób – czego oczywiście nie chcemy – naruszyliśmy Twoje prawa lub nie zapewniliśmy bezpieczeństwa Twoim danym osobowym, przysługuje ci prawo wniesienia skargi do organu nadzorczego, którym obecnie jest Prezes Urzędu Ochrony Danych Osobowych
            </p>
            <h2 className="privacy-policy__list-title">9. Zautomatyzowane podejmowanie decyzji oraz informacja o profilowaniu.</h2>
            <p className="privacy-policy__list-content">
                W oparciu o Twoje dane nie są podejmowane przez nas żadne decyzje, które miałyby charakter zautomatyzowany, to znaczy odbywałyby się bez udziału człowieka. Nie podejmujemy także żadnych działań, które miałyby na celu profilowanie Twojej osoby.
            </p>
            <h2 className="privacy-policy__list-title">10. Jak chronimy Twoje dane?</h2>
            <p className="privacy-policy__list-content">
                W celu zapewnienia bezpieczeństwa Twoich danych stosujemy wymagane prawem środki organizacyjne i techniczne. W naszej siedzibie zainstalowaliśmy niezbędne zabezpieczenia fizyczne, aby uniemożliwić dostęp do danych osobom nieuprawnionym. Nasi pracownicy posiadają wymagane upoważnienia oraz mogą przetwarzać dane w sposób ograniczony, tzn. tylko w takim zakresie, w jakim jest to konieczne do prawidłowego wykonywania przez nich obowiązków służbowych.
            </p>
            <h2 className="privacy-policy__list-title">11. Ochrona prywatności osób niepełnoletnich</h2>
            <p className="privacy-policy__list-content">
                Nasza strona nie monitoruje i nie weryfikuje informacji na temat wieku użytkowników, nadawców i odbiorców wiadomości. Informacje kontaktowe od odwiedzających (takie jak numery telefonów i adresy poczty elektronicznej użytkowników) są wykorzystywane do realizacji zamówień czy wysyłania informacji o naszej firmie.
                Osoby niepełnoletnie nie powinny wysyłać żadnych informacji ani dokonywać zamówień lub subskrybować usług świadczonych przez naszą firmę, bez zgody swoich rodziców lub opiekunów prawnych. Zgody takiej będziemy wymagać w każdym przypadku, gdy uzyskamy wiedzę, że użytkownik jest osobą niepełnoletnią („dzieckiem”) w rozumieniu krajowych przepisów o ochronie danych osobowych.
            </p>
            <h1 className="privacy-policy__title">Pliki cookies</h1>
            <h2 className="privacy-policy__list-title">1. Czym są pliki cookies i do czego służą?</h2>
            <p className="privacy-policy__list-content">
                Cookies to pliki tekstowe, które zapisywane są w Twoim urządzeniu i używane przez serwer do rozpoznania tego urządzenia przy ponownym połączeniu. Cookies są pobierane przy każdym „wejściu” i „wyjściu” z strony. Cookies nie służą do ustalania Twojej tożsamości, a jedynie Twojego urządzenia – m.in. po to, by po rozpoznaniu  używanej przez Ciebie  przeglądarki, wyświetlany obraz był jak najlepiej dopasowany do możliwości technicznych sprzętu (np. jego rozdzielczości) lub jego typu (wersja „stacjonarna” –  desktopowa lub mobilna).
                Cookies są stosowane najczęściej w przypadku liczników, sond, sklepów internetowych, stron wymagających logowania, reklam i do monitorowania aktywności odwiedzających. Ciasteczka umożliwiają także m.in. zapamiętanie Twoich zainteresowań i dostosowanie do nich stron internetowych w zakresie wyświetlanych treści oraz dopasowania reklam.
                Cookies używają obecnie praktycznie wszystkie działające w Internecie serwisy – wyszukiwarki, strony informacyjne, blogi, sklepy internetowe, strony urzędów, czasopism i gazet, itd. Korzysta z nich także nasza strona.
            </p>
            <p className="privacy-policy__list-content">
                Więcej informacji na temat ciasteczek można znaleźć na stronie: <a href="www.wszystkoociasteczkach.pl">www.wszystkoociasteczkach.pl</a>
            </p>
            <h2 className="privacy-policy__list-title">2. Co robią cookies?</h2>
            <h2 className="privacy-policy__list-header">Generalnie działają one na następujących zasadach:</h2>
            <ul>
                <li>identyfikują dane komputera i przeglądarki używanych do przeglądania stron internetowych – pozwalają np. dowiedzieć się czy dany komputer już odwiedzał stronę,</li>
                <li>dane pozyskane z „cookies” nie są w żaden sposób łączone z danymi osobowymi użytkowników pozyskanymi np. podczas rejestracji w serwisach,</li>
                <li>nie są szkodliwe ani dla Ciebie ani dla Twoich komputerów, czy smartfonów – nie wpływają na sposób ich działania,</li>
                <li>nie powodują zmian konfiguracyjnych w urządzeniach końcowych, ani w oprogramowaniu zainstalowanym na tych urządzeniach,</li>
                <li>domyślne parametry „ciasteczek” pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył,</li>
                <li>na podstawie Twoich zachowań na odwiedzanych stronach internetowych przekazują do serwerów informacje, dzięki którym wyświetlana strona jest lepiej dopasowana do indywidualnych preferencji.</li>
            </ul>
            <h2 className="privacy-policy__list-title">3. Jakie są rodzaje plików cookies?</h2>
            <h2 className="privacy-policy__list-header">Występują następujące typy cookies:</h2>
            <ul>
                <li>
                    „Ciasteczka sesyjne” – (ang. session cookies) są tymczasowymi plikami, przechowywanymi w pamięci przeglądarki do momentu zakończenia jej sesji (czyli do chwili zamknięcia przeglądarki). Te pliki są obowiązkowe, aby niektóre aplikacje lub funkcjonalności działały poprawnie. Po zamknięciu przeglądarki powinny być automatycznie usuwane z urządzenia, na którym oglądałeś stronę,
                </li>
                <li>
                    „Ciasteczka stałe” – (ang. persistent cookies) ułatwiają korzystanie z często odwiedzanych stron (np. zapamiętują ulubiony układ kolorystyczny, albo układ menu na Twoich ulubionych stronach). Pliki te przechowywane są w odpowiednim folderze przez dłuższy czas, który możesz regulować w ustawieniach używanej przeglądarki. Za każdym razem, gdy odwiedzasz stronę, dane z tych ciasteczek są przekazywane na serwer. Ten typ plików cookie, z angielska jest czasem nazywany „śledzącymi” (tracking cookies),
                </li>
                <li>
                    „Ciasteczka zewnętrzne” – (ang. third parties cookies) – są plikami pochodzącymi zazwyczaj z serwerów reklamowych, serwerów wyszukujących itd., współpracujących z właścicielem danej strony. Dzięki nim wyświetlane reklamy są dostosowane do Twoich preferencji i zwyczajów, co w zamian niejednokrotnie pozwala na bezpłatne korzystanie z części zawartości serwisu. Z ich pomocą są również zliczane „kliknięcia” na reklamy, preferencje użytkowników etc.,
                </li>
            </ul>
            <h2 className="privacy-policy__list-title">4. Czy musisz wyrazić zgodę na używanie przez nas cookies?</h2>
            <h2 className="privacy-policy__list-header">Pamiętaj, że masz możliwość samodzielnego zarządzania „cookies”. Umożliwiają to np. przeglądarki internetowe, z których korzystasz (zazwyczaj domyślnie mechanizm jest włączony). W najpopularniejszych przeglądarkach masz możliwość:</h2>
            <ul>
                <li>
                    zaakceptowania obsługi „cookies”, co pozwoli Ci na pełne korzystanie z opcji oferowanych przez witryny internetowe,
                </li>
                <li>
                    zarządzania cookies na poziomie pojedynczych, wybranych przez Ciebie witryn,
                </li>
                <li>
                    określenia ustawień dla różnych typów „cookie”, na przykład akceptowania plików trwałych jako sesyjnych itp.,
                </li>
                <li>
                    blokowania lub usuwania cookies.
                </li>
            </ul>
            <h2 className="privacy-policy__list-header">Informacje na temat możliwości włączenia i wyłączenia ciasteczek w najbardziej popularnych przeglądarkach znajdziesz pod następującymi linkami:</h2>
            <ul>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=pl&hlrm=en&p=cpn_cookies">Google Chrome</a></li>
                <li><a href="http://windows.microsoft.com/pl-PL/windows7/Block-enable-or-allow-cookies">Internet Explorer</a></li>
                <li><a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Mozilla Firefox</a></li>
                <li><a href="http://help.opera.com/Linux/9.60/pl/cookies.html">Opera</a></li>
                <li><a href="http://support.apple.com/kb/index?page=search&src=support_site.home.search&locale=en_US&q=safari%20cookies">Safari</a></li>
            </ul>
            <h2 className="privacy-policy__list-content">
                Pozostawienie prze Ciebie ustawień przeglądarki bez zmian oznacza zgodę na stosowanie przez nas ciasteczek.  Ich blokowanie lub wyłączenie obsługi niektórych ich rodzajów może uniemożliwić Ci natomiast skorzystanie z pełnej funkcjonalności strony lub zakłócić jej poprawne funkcjonowanie.
            </h2>
            <h2 className="privacy-policy__list-title">5. Do czego wykorzystujemy cookies?</h2>
            <h2 className="privacy-policy__list-header">W ramach strony stosowane są ciasteczka sesyjne. Wykorzystujemy je w następujących celach:</h2>
            <ul>
                <li>
                    utrzymanie sesji Użytkownika strony. W celu poprawnego wyświetlania strony zbierane są następujące informacje: nazwa i wersja przeglądarki internetowej, ustawienia języka, data i godzina wysłania żądania do serwera, IP z którego zostało wysłane żądanie, żądany URL.  Dane te zbierane są w celu umożliwienia prawidłowej obsługi strony.
                </li>
            </ul>





        </div>
    )
}

export default PrivacyPolicy
